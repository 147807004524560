import React, { FC, useContext, useEffect, useState } from 'react';
import { LendingPool } from '../Common/Models/LendingGraph';
import { getLeverage, getLiquidationPrices, roundAndFormatNumber } from '../Common/HelperFunctions';
import { BeefyFarmApyStats } from '../Common/Models/BeefyFarmApyStats';
import MiniPrice from '../Components/CardControls/MiniPrice';
import iconLock from '../Images/lock.png';
import iconPieChart from '../Images/pie-chart.png';
import iconStaked from '../Images/staked-icon.png';
import iconStakedUsd from '../Images/staked-dollars-icon.png';
import LeverageModal from '../Modals/LeverageModal';
import ConnectButton from '../Components/ConnectButton/ConnectButton';
import { Web3Context } from '../Components/Web3Context/Web3Context';
import DeleverageModal from '../Modals/DeleverageModal';
import { LpStat } from '../Common/Models/LPStat';
import {
  getLendingPoolTokenBorrowAPY,
  getLendingPoolTokenSupplyAPY,
  getLendingPoolTokenTotalBorrowInUSD,
  getLendingPoolTokenTotalSupplyInUSD,
  getLendingPoolTokenUtilizationRate,
} from '../Common/LendingPoolFunctions';

interface BorrowCardProps {
  className?: string;
  featured?: boolean;
  lendingPool: LendingPool;
  farmApyStats?: BeefyFarmApyStats;
  imageUrl: string;
  fetchAssetBalanceFunction?: (poolAsset: string) => Promise<number>;
  fetchAssetDebtFunction?: (poolAsset: string) => Promise<number>;
  fetchAssetExchangeRateFunction?: (poolAsset: string) => Promise<number>;
  leverageFunction?: (
    poolAsset: string,
    amountADesired: number,
    amountBDesired: number,
    amountAMin: number,
    amountBMin: number,
  ) => void;
  deleverageFunction?: (poolAsset: string, redeemTokens: number, amountAMin: number, amountBMin: number) => void;
  approveFunction?: (poolAsset: string) => void;
  borrowApproveFunction?: (poolAsset: string) => void;
  getAllowanceFunction?: (poolAsset: string) => Promise<number>;
  getBorrowAllowanceFunction?: (poolAsset: string) => Promise<number>;
  lpStat?: LpStat;
}

interface BorrowProperties {
  left: string;
  name: string;
  right: string;
}

const BorrowCard: FC<BorrowCardProps> = (props) => {
  const { connectWeb3, disconnectWeb3, web3Connected, walletAddress } = useContext(Web3Context);
  const [stakedBalance, setStakedBalance] = useState<number>(0);
  const [stakedDebt, setStakedDebt] = useState<number>(0);
  const [stakedBalanceMultiplier, setStakedBalanceMultiplier] = useState<number>(1);
  const [leverageModalIsOpen, setLeverageModalIsOpen] = React.useState(false);
  const [deleverageModalIsOpen, setDeleverageModalIsOpen] = React.useState(false);
  const [allowance, setAllowance] = useState<number>(0); // This is to deleverage
  const [borrowAllowance0, setBorrowAllowance0] = useState<number>(0); // This is to leverage
  const [borrowAllowance1, setBorrowAllowance1] = useState<number>(0);
  const [debt0, setDebt0] = useState<number>(0); // This is to leverage
  const [debt1, setDebt1] = useState<number>(0);
  const [debt0Usd, setDebt0Usd] = useState<number>(0); // This is to leverage
  const [debt1Usd, setDebt1Usd] = useState<number>(0);

  const stakeApr0 = getLendingPoolTokenSupplyAPY(props.lendingPool.borrowable0) * 100;
  const stakeApr1 = getLendingPoolTokenSupplyAPY(props.lendingPool.borrowable1) * 100;

  const borrowApr0 = getLendingPoolTokenBorrowAPY(props.lendingPool.borrowable0) * 100;
  const borrowApr1 = getLendingPoolTokenBorrowAPY(props.lendingPool.borrowable1) * 100;

  const utilization0 = getLendingPoolTokenUtilizationRate(props.lendingPool.borrowable0) * 100;
  const utilization1 = getLendingPoolTokenUtilizationRate(props.lendingPool.borrowable1) * 100;

  var farmApr = props.farmApyStats
    ? Number(
        props.farmApyStats[
          'bomb-' +
            props.lendingPool?.borrowable0.underlying.symbol.toLowerCase() +
            '-' +
            props.lendingPool?.borrowable1.underlying.symbol.toLowerCase()
        ].totalApy,
        // ].vaultApr,
      ) * 100
    : 0;
  var collateralExchangeRateUsd =
    Number(props.lendingPool?.collateral.totalBalanceUSD) / Number(props.lendingPool?.collateral.totalBalance);

  useEffect(() => {
    if (web3Connected) {
      fetchAssetStakedBalance();
      fetchAssetStakedDebt();
      fetchApproval();
      fetchBorrowApproval0();
      fetchBorrowApproval1();
    }
  }, [props.lendingPool, web3Connected]);

  async function fetchAssetStakedBalance() {
    if (props.fetchAssetBalanceFunction && props.fetchAssetExchangeRateFunction && props.lendingPool?.pair) {
      var balance = await props.fetchAssetBalanceFunction(props.lendingPool.collateral.id);
      var multiplier = Number(await props.fetchAssetExchangeRateFunction(props.lendingPool.collateral.id));
      var trueBalance = balance * multiplier;
      // debugger;
      setStakedBalance(trueBalance);
      setStakedBalanceMultiplier(multiplier);
    }
  }

  async function fetchAssetStakedDebt() {
    if (props.fetchAssetDebtFunction && props.fetchAssetExchangeRateFunction && props.lendingPool?.pair) {
      const debt0 = await props.fetchAssetDebtFunction(props.lendingPool.borrowable0.id);
      const debt1 = await props.fetchAssetDebtFunction(props.lendingPool.borrowable1.id);
      const debt0Usd = debt0 * props.lendingPool.borrowable0.underlying.derivedUSD;
      const debt1Usd = debt1 * props.lendingPool.borrowable1.underlying.derivedUSD;
      const lpUsd =
        props.lendingPool.pair.derivedUSD /
        Number(await props.fetchAssetExchangeRateFunction(props.lendingPool.collateral.id));
      // var multiplier = Number(await props.fetchAssetExchangeRateFunction(props.lendingPool.borrowable0.id));
      // var trueDebt = debt * multiplier;
      // debugger;
      const debtInLps = (debt0Usd + debt1Usd) / lpUsd;
      setStakedDebt(debtInLps);
      setDebt0(debt0);
      setDebt1(debt1);
      setDebt0Usd(debt0Usd);
      setDebt1Usd(debt1Usd);
    }
  }

  async function fetchApproval() {
    // debugger;
    if (props.getAllowanceFunction && props.lendingPool?.collateral.id) {
      var allow = await props.getAllowanceFunction(props.lendingPool.collateral.id);
      setAllowance(allow);
    }
  }

  async function fetchBorrowApproval0() {
    // debugger;
    if (props.getBorrowAllowanceFunction && props.lendingPool?.borrowable0.id) {
      var allow = await props.getBorrowAllowanceFunction(props.lendingPool?.borrowable0.id);
      setBorrowAllowance0(allow);
    }
  }

  async function fetchBorrowApproval1() {
    // debugger;
    if (props.getBorrowAllowanceFunction && props.lendingPool?.borrowable1.id) {
      var allow = await props.getBorrowAllowanceFunction(props.lendingPool?.borrowable1.id);
      setBorrowAllowance1(allow);
    }
  }

  async function borrowApprove(address: string) {
    if (props.borrowApproveFunction) props.borrowApproveFunction(address);
    if (props.getBorrowAllowanceFunction) {
      var a = await props.getBorrowAllowanceFunction(address);
      setBorrowAllowance0(a);
    }
  }

  async function deleverage(redeemTokens: number, amountAMin: number, amountBMin: number) {
    if (props.deleverageFunction && props.fetchAssetExchangeRateFunction && props.lendingPool) {
      const multiplier = Number(await props.fetchAssetExchangeRateFunction(props.lendingPool.collateral.id));
      props.deleverageFunction(props.lendingPool?.id, redeemTokens / multiplier, amountAMin, amountBMin);
      await fetchAssetStakedBalance();
      await fetchAssetStakedDebt();
    }
  }

  async function leverage(amountADesired: number, amountBDesired: number, amountAMin: number, amountBMin: number) {
    if (props.leverageFunction && props.lendingPool) {
      props.leverageFunction(props.lendingPool?.id, amountADesired, amountBDesired, amountAMin, amountBMin);
      await fetchAssetStakedBalance();
      await fetchAssetStakedDebt();
    }
  }

  let stakedBalanceUsd = (stakedBalance / stakedBalanceMultiplier) * collateralExchangeRateUsd;
  let lpEquity = stakedBalance - stakedDebt;
  let currentLeverage = getLeverage(
    stakedBalance / stakedBalanceMultiplier,
    Number(props.lendingPool.pair.totalSupply) / Number(props.lendingPool.pair.reserve0) / 2,
    Number(props.lendingPool.pair.totalSupply) / Number(props.lendingPool.pair.reserve1) / 2,
    debt0,
    debt1,
    {
      changeCollateral: 0,
      changeBorrowedA: 0,
      changeBorrowedB: 0,
    },
  );
  const lpEquityUsd = (lpEquity / stakedBalanceMultiplier) * collateralExchangeRateUsd;
  const liquidationPrices = getLiquidationPrices(
    stakedBalance / stakedBalanceMultiplier,
    Number(props.lpStat?.token0DenomLPPrice),
    Number(props.lpStat?.token1DenomLPPrice),
    debt0,
    debt1,
    Number(props.lpStat?.twap),
    Number(props.lendingPool?.collateral.safetyMargin),
    Number(props.lendingPool?.collateral.liquidationIncentive),
    {
      changeCollateral: 0,
      changeBorrowedA: 0,
      changeBorrowedB: 0,
    },
  );
  const marketTwap = Number(props.lpStat?.reserve1) / Number(props.lpStat?.reserve0);

  const interest0Usd = debt0Usd * (borrowApr0 / 100);
  const interest1Usd = debt1Usd * (borrowApr1 / 100);
  const totalInterest = interest0Usd + interest1Usd;
  let borrowApr;
  if (totalInterest > 0) {
    borrowApr = totalInterest / lpEquityUsd;
  }

  const leveragedFarmApr = currentLeverage * (farmApr / 100);
  const totalApr = leveragedFarmApr - (borrowApr ? borrowApr : 0);

  const cardContent = () => {
    return [
      {
        left: '$' + roundAndFormatNumber(getLendingPoolTokenTotalSupplyInUSD(props.lendingPool?.borrowable0), 2),
        name: 'Total Supply',
        right: '$' + roundAndFormatNumber(getLendingPoolTokenTotalSupplyInUSD(props.lendingPool?.borrowable1), 2),
      },
      {
        left: '$' + roundAndFormatNumber(getLendingPoolTokenTotalBorrowInUSD(props.lendingPool?.borrowable0), 2),
        name: 'Total Borrowed',
        right: '$' + roundAndFormatNumber(getLendingPoolTokenTotalBorrowInUSD(props.lendingPool?.borrowable1), 2),
      },
      {
        left: roundAndFormatNumber(utilization0) + '%',
        name: 'Utilization',
        right: roundAndFormatNumber(utilization1) + '%',
      },
      {
        left: roundAndFormatNumber(stakeApr0, 2) + '%',
        name: 'Lend APR',
        right: roundAndFormatNumber(stakeApr1, 2) + '%',
      },
      {
        left: roundAndFormatNumber(borrowApr0, 2) + '%',
        name: 'Borrow APR',
        right: roundAndFormatNumber(borrowApr1, 2) + '%',
      },
    ] as BorrowProperties[];
  };

  function openLeverageModal() {
    if (stakedBalance - stakedDebt > 0) {
      setLeverageModalIsOpen(true);
    } else {
      alert('LP Equity must be greater than 0 to leverage. Please stake first in the farm.');
    }
  }

  function closeLeverageModal() {
    setLeverageModalIsOpen(false);
  }

  function openDeleverageModal() {
    setDeleverageModalIsOpen(true);
  }

  function closeDeleverageModal() {
    setDeleverageModalIsOpen(false);
  }

  return (
    <div
      className={
        'px-6 pb-6 pt-3 overflow-hidden bg-white card relative shadow rounded-xl text-left flex flex-col text-gray-800 min-w-full md:min-w-fit w-[22rem] min-w-fit dark:text-light' +
        (props.featured
          ? ' p-0.5 bg-gradient-to-tr from-yellow-500 via-red-500 to-indigo-700 '
          : ' bg-white dark:bg-dark2-500') +
        (props.className ? ` ${props.className}` : '')
      }
    >
      <div className="flex flex-col items-center relative">
        <div className="flex font-urbanist mb-2">
          <div className="text-3xl font-extrabold">
            {props.lendingPool?.borrowable0?.underlying.symbol} - {props.lendingPool?.borrowable1?.underlying.symbol}
          </div>
        </div>
        <div className="w-8/12 h-14 border-t-[1px] border-l-[1px] border-r-[1px] border-yellow-200 absolute top-14"></div>
        <div className="mb-6 relative">
          <div className="w-28 h-28 rounded-full shadow-xl bg-white">
            <div
              className={'bg-no-repeat h-full w-full bg-contain bg-center scale-75'}
              style={{ backgroundImage: `url('${props.imageUrl}')` }}
            ></div>
          </div>
        </div>
        <div className="mb-6 w-full relative gap-2 flex flex-col">
          {cardContent().map((x) => {
            return (
              <div key={x.name} className="flex justify-between w-full bg-white dark:bg-dark2-500 relative">
                <div className="absolute left-0 mx-auto top-1 right-0 w-8/12 h-1 rounded-full bg-primary-500 drop-shadow-[0px_0px_4px_rgba(0,0,0,0.7)] dark:drop-shadow-[0px_0px_4px_rgba(255,255,255,0.7)]"></div>
                <div className="absolute left-0 mx-auto bottom-1 right-0 w-8/12 h-1 rounded-full bg-primary-500 drop-shadow-[0px_0px_4px_rgba(0,0,0,0.7)] dark:drop-shadow-[0px_0px_4px_rgba(255,255,255,0.7)]"></div>
                <div className="flex justify-between w-full bg-white dark:bg-dark2-500 relative py-2">
                  <div className="text-xs font-extrabold">{x.left}</div>
                  <div className="text-xs italic uppercase">{x.name}</div>
                  <div className="text-xs font-extrabold">{x.right}</div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex justify-evenly w-full gap-4 mb-4 flex-wrap">
          <MiniPrice
            icon={iconPieChart}
            iconAlt="pie chart"
            title="Farm APY"
            value={roundAndFormatNumber(farmApr, 2) + '%'}
          />
        </div>
        <p className="border-t w-full">&nbsp;</p>
        {web3Connected && props.lendingPool?.pair.derivedUSD != undefined && (
          <>
            <div className="flex justify-evenly w-full gap-4 mb-4 flex-wrap">
              <MiniPrice
                icon={iconStaked}
                iconAlt="lock"
                title="Total Collateral"
                value={
                  '$' +
                  roundAndFormatNumber(stakedBalanceUsd, 2) +
                  ' (' +
                  roundAndFormatNumber(stakedBalance, 3) +
                  ' LP)'
                }
              />
              <MiniPrice
                icon={iconLock}
                iconAlt="lock"
                title="Total Debt"
                textColor={'lightcoral'}
                value={
                  '-$' +
                  roundAndFormatNumber((stakedDebt / stakedBalanceMultiplier) * collateralExchangeRateUsd, 2) +
                  ' (-' +
                  roundAndFormatNumber(stakedDebt, 3) +
                  ' LP)'
                }
                right
              />
            </div>
            <div className="flex justify-evenly w-full gap-4 mb-4 flex-wrap">
              <MiniPrice
                icon={iconStakedUsd}
                iconAlt="pie chart"
                title="LP Equity"
                value={'$' + roundAndFormatNumber(lpEquityUsd, 2) + ' (' + roundAndFormatNumber(lpEquity, 3) + ' LP)'}
              />
              <MiniPrice
                icon={iconPieChart}
                iconAlt="pie chart"
                title="Current Leverage"
                value={roundAndFormatNumber(currentLeverage, 2) + 'x'}
                right
              />
            </div>
            <div className="flex justify-evenly w-full gap-4 mb-4 flex-wrap text-center">
              <MiniPrice
                icon={iconStakedUsd}
                iconAlt="pie chart"
                title="Liquidation prices"
                value={
                  props.lpStat?.ratio
                    ? roundAndFormatNumber(liquidationPrices[0] * props.lpStat?.ratio, 4) +
                      ' - ' +
                      roundAndFormatNumber(liquidationPrices[1] * props.lpStat?.ratio, 4)
                    : ''
                }
              />
            </div>
            <div className="flex justify-evenly w-full gap-4 mb-4 flex-wrap text-center">
              <MiniPrice
                icon={iconStakedUsd}
                iconAlt="pie chart"
                title={props.lpStat && props.lpStat?.ratio > 1 ? 'TWAP PEG' : 'TWAP Price'}
                value={
                  props.lpStat
                    ? roundAndFormatNumber(props.lpStat?.twap * props.lpStat?.ratio, 4) +
                      ' (current: ' +
                      roundAndFormatNumber(marketTwap * props.lpStat?.ratio, 4) +
                      ')'
                    : ''
                }
              />
            </div>
            <p className="border-t w-full">&nbsp;</p>
            <div className="flex justify-evenly w-full gap-4 mb-4 flex-wrap">
              <MiniPrice
                icon={iconPieChart}
                iconAlt="pie chart"
                title="Leveraged Farm APY"
                value={leveragedFarmApr ? roundAndFormatNumber(leveragedFarmApr * 100, 2) + '%' : '-'}
              />
              <MiniPrice
                icon={iconPieChart}
                iconAlt="pie chart"
                title="Borrow APR"
                textColor={'lightcoral'}
                value={borrowApr ? roundAndFormatNumber(borrowApr * 100, 2) + '%' : '-'}
                right
              />
            </div>
            <div className="flex justify-evenly w-full gap-4 mb-4 flex-wrap text-center">
              <MiniPrice
                icon={iconPieChart}
                iconAlt="pie chart"
                title="Total APY"
                value={totalApr ? roundAndFormatNumber(totalApr * 100, 2) + '%' : '-'}
              />
            </div>
          </>
        )}
        <div className="flex gap-3 w-full flex-wrap">
          {!web3Connected ? (
            <ConnectButton
              className=""
              connectWeb3={connectWeb3}
              disconnectWeb3={disconnectWeb3}
              web3Connected={web3Connected}
              walletAddress={walletAddress}
            />
          ) : (
            <>
              {/* {console.log(props.lpStat)} */}
              {props.lendingPool?.pair && props.borrowApproveFunction && props.lpStat ? (
                <>
                  <button
                    className="flex-1 rounded-full bg-gradient-to-b from-[#fee22e] to-[#fed647] text-dark uppercase px-5 py-2 font-semibold drop-shadow-[0px_4px_5px_rgba(0,0,0,0.25)] hover:bg-[#fee22e] focus:outline-none focus:ring focus:ring-[#fee22e] focus:ring-opacity-75 whitespace-nowrap"
                    onClick={() => openLeverageModal()}
                  >
                    Leverage
                  </button>
                  {leverageModalIsOpen && (
                    <LeverageModal
                      isOpen={leverageModalIsOpen}
                      title="Leverage"
                      onRequestClose={closeLeverageModal}
                      leverageFunction={(
                        amountADesired: number,
                        amountBDesired: number,
                        amountAMin: number,
                        amountBMin: number,
                      ) => {
                        closeLeverageModal();
                        return leverage(amountADesired, amountBDesired, amountAMin, amountBMin);
                      }}
                      stakedBalance={stakedBalance}
                      stakedDebt={stakedDebt}
                      stakedBalanceMultiplier={stakedBalanceMultiplier}
                      borrowAllowance0={borrowAllowance0}
                      borrowAllowance1={borrowAllowance1}
                      borrowable0={props.lendingPool?.borrowable0}
                      borrowable1={props.lendingPool?.borrowable1}
                      currentLeverage={currentLeverage}
                      lpStat={props.lpStat}
                      debt0={debt0}
                      debt1={debt1}
                      debt0Usd={debt0Usd}
                      debt1Usd={debt1Usd}
                      collateral={props.lendingPool?.collateral}
                      farmApr={farmApr}
                      lpEquity={lpEquity}
                      lpEquityUsd={lpEquityUsd}
                      borrowApproveFunction={(poolAsset: string) => {
                        return borrowApprove(poolAsset);
                      }}
                      amountElementId={'leverage_' + props.lendingPool?.borrowable0?.id}
                    />
                  )}
                </>
              ) : null}

              {props.lendingPool?.pair && props.approveFunction && props.lpStat ? (
                <>
                  <button
                    className="flex-1 rounded-full bg-gradient-to-b from-[#b6b7c2] to-[#8c95a7] text-dark uppercase px-5 py-2 font-semibold drop-shadow-[0px_4px_5px_rgba(0,0,0,0.25)] hover:bg-[#8c95a7] focus:outline-none focus:ring focus:ring-[#8c95a7] focus:ring-opacity-75 whitespace-nowrap"
                    onClick={() => openDeleverageModal()}
                  >
                    De-Leverage
                  </button>
                  {deleverageModalIsOpen && (
                    <DeleverageModal
                      isOpen={deleverageModalIsOpen}
                      title="Deleverage"
                      onRequestClose={closeDeleverageModal}
                      deleverageFunction={(lpsToRedeem: number, amountAMin: number, amountBMin: number) => {
                        closeDeleverageModal();
                        return deleverage(lpsToRedeem, amountAMin, amountBMin);
                      }}
                      allowance={allowance}
                      stakedBalance={stakedBalance}
                      stakedBalanceMultiplier={stakedBalanceMultiplier}
                      currentLeverage={currentLeverage}
                      collateral={props.lendingPool?.collateral}
                      lpStat={props.lpStat}
                      debt0={debt0}
                      debt1={debt1}
                      pair={props.lendingPool?.pair}
                      approveFunction={props.approveFunction}
                      amountElementId={'deleverage_' + props.lendingPool?.borrowable0?.id}
                    />
                  )}
                </>
              ) : null}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BorrowCard;
