import { Dialog, Transition } from '@headlessui/react';
import React, { FC, Fragment, useRef, useState } from 'react';
import { abbreviateAddress } from '../../Common/HelperFunctions';

import { ReactComponent as IconWalletConnect } from '../../Images/walletconnect.svg';
import { ReactComponent as IconMetaMask } from '../../Images/metamask.svg';
import { ReactComponent as IconTrustWallet } from '../../Images/trustwallet.svg';
// import { ReactComponent as IconFortmatic } from '../../Images/fortmatic.svg';
// import { ReactComponent as IconTorus } from '../../Images/torus.svg';

interface ConnectButtonProps {
  className?: string;

  walletAddress: string;
  web3Connected: boolean;
  connectWeb3: (mode: string) => void;
  disconnectWeb3: () => void;
}

const ConnectButton: FC<ConnectButtonProps> = (props) => {
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  async function connect() {
    if (props.web3Connected == false) {
      setOpen(true);
    } else {
      props.disconnectWeb3();
    }
  }

  async function connectMetaMask() {
    props.connectWeb3('metamask');
    setOpen(false);
  }

  async function connectWalletConnect() {
    props.connectWeb3('walletconnect');
    setOpen(false);
  }

  // async function connectFortmatic() {
  //   props.connectWeb3('fortmatic');
  //   setOpen(false);
  // }

  // async function connectTorus() {
  //   props.connectWeb3('torus');
  //   setOpen(false);
  // }

  function shortenWallet() {
    var before = 6;
    var after = 4;

    if (window.innerWidth < 640) {
      before = 4;
      after = 3;
    }

    return abbreviateAddress(props.walletAddress, before, after);
  }

  return (
    <>
      <a
        href="#"
        className={
          'flex items-center text-black drop-shadow-md bg-gradient-to-r from-[#E4E3DE] to-[#FFF] py-1 px-4 rounded-full text-base font-medium text-dark1-500 hover:bg-secondary-500 hover:text-black transition-all duration-250 group' +
          props.className
        }
        onClick={connect}
      >
        <span className="group-hover:hidden">{props.web3Connected == false ? 'Connect Wallet' : shortenWallet()}</span>
        <span className="hidden group-hover:flex">
          {props.web3Connected == false ? 'Connect Wallet' : 'Disconnect Wallet'}
        </span>
        <div className="w-4 h-4 bg-primary-500 rounded-full ml-2"></div>
      </a>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-96 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Select a wallet provider
                    </Dialog.Title>
                    <div className="mt-6 flex">
                      <div
                        onClick={connectMetaMask}
                        className="flex-1 cursor-pointer hover:rounded-md hover:bg-gray-200"
                      >
                        <IconMetaMask className="m-auto w-10 h-10" />
                        MetaMask
                      </div>
                      <div
                        onClick={connectMetaMask}
                        className="flex-1 cursor-pointer hover:rounded-md hover:bg-gray-200"
                      >
                        <IconTrustWallet className="m-auto w-10 h-10" />
                        Trust Wallet
                      </div>
                      <div
                        onClick={connectWalletConnect}
                        className="flex-1 cursor-pointer hover:rounded-md hover:bg-gray-200"
                      >
                        <IconWalletConnect className="m-auto w-10 h-10" />
                        WalletConnect
                      </div>
                      {/* <div
                        onClick={connectFortmatic}
                        className="flex-1 cursor-pointer hover:rounded-md hover:bg-gray-200"
                      >
                        <IconFortmatic className="m-auto w-10 h-10" />
                        Fortmatic
                      </div> */}
                      {/* <div onClick={connectTorus} className="flex-1 cursor-pointer hover:rounded-md hover:bg-gray-200">
                        <IconTorus className="m-auto w-10 h-10" />
                        Torus
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default ConnectButton;
