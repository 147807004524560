import { FC } from "react";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";

interface RadialGraphProps {
    className?: string;
    value: number;
}

const Gauge: FC<RadialGraphProps> = (props) => {
    const deg = -40 + (props.value / 100) * 260;
    return (
        <>
            <div className="text-white text-xs text-center font-bold">{props.value}%</div>
            <CircularProgressbarWithChildren
                className={"h-16" + (props.className ? ` ${props.className}` : "")}
                value={props.value}
                strokeWidth={5}
                circleRatio={0.75}
                styles={buildStyles({
                    strokeLinecap: "butt",
                    rotation: 1 / 2 + 1 / 8,
                    trailColor: "#202020",
                    pathColor: "#fff",
                })}
            >
                <div
                    className={
                        "absolute rounded-full inset-2 bg-[#f7cb20] dark:bg-[#362c75] drop-shadow-[3px_2px_3px_rgba(246,163,39,1)] dark:drop-shadow-[3px_2px_3px_rgba(50,50,50,1)]"
                    }
                >
                    <div className="w-full h-full" style={{ transform: `rotate(${deg}deg)` }}>
                        <div className="w-2 h-2 bg-white rounded-full absolute inset-0 m-auto translate-x-[-1rem]"></div>
                    </div>
                </div>
            </CircularProgressbarWithChildren>
        </>
    );
};

export default Gauge;
