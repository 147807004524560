import React, { useContext, useState } from "react";
import { abbreviateAddress, round, roundAndFormatNumber } from "../Common/HelperFunctions";
import { Web3Context } from "../Components/Web3Context/Web3Context";

function AdminPage() {
    const { isAdmin } = useContext(Web3Context);

    return (
        <>
            {isAdmin == true ? (
                <main className="flex flex-col w-100 flex-1 relative focus:outline-none dark:text-light">
                    <h1 className="text-black-500  text-left text-3xl font-semibold">Admin Tools</h1>
                    <div className="max-w-lg lg:max-w-none mt-6 bg-white dark:bg-dark2-500 rounded-lg p-6 flex-1">Coming soon...</div>
                    {/* <div className="max-w-lg grid gap-8 grid-cols-2 md:grid-cols-3 lg:grid-cols-5 lg:max-w-none mt-6"> */}
                    {/* </div> */}
                </main>
            ) : (
                <main className="flex flex-col w-100 flex-1 relative focus:outline-none dark:text-light">
                    <h1 className="text-black-500 text-left text-3xl font-semibold">Disclaimer</h1>
                    <div className="max-w-lg lg:max-w-none mt-6 bg-white dark:bg-dark2-500 rounded-lg p-6 flex-1">
                        This wallet is not eligible for Admin.
                    </div>
                </main>
            )}
        </>
    );
}

export default AdminPage;
