import { Borrowable } from "./Models/LendingGraph";

const SECONDS_IN_YEAR = 365 * 24 * 3600;

const toAPY = (value: number) : number => {
    return value * SECONDS_IN_YEAR;
};

export const getLendingPoolTokenTotalSupplyInUSD = (
    borrowable: Borrowable
): number => {
    const totalBalance = parseFloat(borrowable.totalBalance);
    const totalBorrows = parseFloat(borrowable.totalBorrows);
    const supply = totalBalance + totalBorrows;
    const utilizationRate = supply === 0 ? 0 : totalBorrows / supply;

    const borrowRate = parseFloat(borrowable.borrowRate);
    const reserveFactor = parseFloat(borrowable.reserveFactor);
    const supplyRate = borrowRate * utilizationRate * (1 - reserveFactor); // TODO: could be a function

    const accrualTimestamp = parseFloat(borrowable.accrualTimestamp);
    const currentTotalSupply = supply * (1 + (Date.now() / 1000 - accrualTimestamp) * supplyRate);
    const tokenPriceInUSD = Number(borrowable.underlying.derivedUSD);

    return currentTotalSupply * tokenPriceInUSD;
};

export const getLendingPoolTokenTotalBorrowInUSD = (
    borrowable: Borrowable
): number => {
    const totalBorrows = parseFloat(borrowable.totalBorrows);
    const accrualTimestamp = parseFloat(borrowable.accrualTimestamp);
    const borrowRate = parseFloat(borrowable.borrowRate);
    const currentTotalBorrow = totalBorrows * (1 + (Date.now() / 1000 - accrualTimestamp) * borrowRate);
    const tokenPriceInUSD = Number(borrowable.underlying.derivedUSD);

    // TODO: it's also from lendingPool[poolTokenType].totalBorrowsUSD. What is different?
    return currentTotalBorrow * tokenPriceInUSD;
};

export const getLendingPoolTokenUtilizationRate = (
    borrowable: Borrowable
): number => {
    const totalBalance = parseFloat(borrowable.totalBalance);
    const totalBorrows = parseFloat(borrowable.totalBorrows);
    const supply = totalBalance + totalBorrows;

    return supply === 0 ? 0 : totalBorrows / supply;
};

export const getLendingPoolTokenSupplyAPY = (
    borrowable: Borrowable
): number => {
    const utilizationRate = getLendingPoolTokenUtilizationRate(borrowable);

    const borrowRate = parseFloat(borrowable.borrowRate);
    const reserveFactor = parseFloat(borrowable.reserveFactor);
    const supplyRate = borrowRate * utilizationRate * (1 - reserveFactor); // TODO: could be a function

    return toAPY(supplyRate);
};

export const getLendingPoolTokenBorrowAPY = (
    borrowable: Borrowable
): number => {
    const borrowRate = parseFloat(borrowable.borrowRate);

    return toAPY(borrowRate);
};
