import React, { FC, useCallback, useEffect, useState } from 'react';
import MiniEditablePrice from '../Components/CardControls/MiniEditablePrice';
import iconLock from '../Images/lock.png';
import { Borrowable, Collateral } from '../Common/Models/LendingGraph';
import { getImpermanentLoss, getLiquidationPrices, round, roundAndFormatNumber } from '../Common/HelperFunctions';
import { LpStat } from '../Common/Models/LPStat';
import InputSlider from '../Components/InputSlider/InputSlider';
import MiniPrice from '../Components/CardControls/MiniPrice';
import iconStakedUsd from '../Images/staked-dollars-icon.png';
import iconStaked from '../Images/staked-icon.png';
import iconPieChart from '../Images/pie-chart.png';

interface LeverageModalProps {
  isOpen: boolean;
  title: string;
  leverageFunction: Function;
  onRequestClose: Function;
  currentLeverage: number;
  amountElementId: string;
  stakedBalance: number;
  stakedBalanceMultiplier: number;
  stakedDebt: number;
  debt0: number;
  debt1: number;
  debt0Usd: number;
  debt1Usd: number;
  collateral: Collateral;
  farmApr: number;
  lpEquity: number;
  lpEquityUsd: number;
  borrowable0: Borrowable;
  borrowable1: Borrowable;
  borrowAllowance0: number;
  borrowAllowance1: number;
  lpStat: LpStat;
  borrowApproveFunction: (poolAsset: string) => void;
}

const LeverageModal: FC<LeverageModalProps> = (props) => {
  if (props.stakedBalance - props.stakedDebt <= 0) {
    throw 'Staked balance - equity cannot be 0';
  }
  const [newLeverage, setNewLeverage] = useState<number>(props.currentLeverage);
  const [maxSlippage, setMaxSlippage] = useState<number>(2);
  const [amountADesired, setAmountADesired] = useState<number>(0);
  const [amountBDesired, setAmountBDesired] = useState<number>(0);
  const [amountAMin, setAmountAMin] = useState<number>(0);
  const [amountBMin, setAmountBMin] = useState<number>(0);
  const [borrowApr, setBorrowApr] = useState<number>(0);
  const [leveragedFarmApr, setLeveragedFarmApr] = useState<number>(0);
  const [totalApr, setTotalApr] = useState<number>(0);
  const [liquidationPrices, setLiquidationPrices] = useState<number[]>([0, Infinity]);
  const [newLiquidationPrices, setNewLiquidationPrices] = useState<number[]>([0, Infinity]);

  async function updateNewLeverage(elem: HTMLInputElement) {
    console.log(`Element: ${elem.id}   Value: ${elem.value}`);
    const unewLeverage = elem.value === '' ? 0 : elem.valueAsNumber;
    updateStats(unewLeverage);
  }

  async function updateStats(unewLeverage: number) {
    const priceA = Number(props.lpStat.totalSupply) / Number(props.lpStat.reserve0) / 2;
    const priceB = Number(props.lpStat.totalSupply) / Number(props.lpStat.reserve1) / 2;
    const diffOne =
      priceA > props.lpStat.token0DenomLPPrice
        ? priceA / props.lpStat.token0DenomLPPrice
        : props.lpStat.token0DenomLPPrice / priceA;
    const adjustFactorOne = Math.pow(getImpermanentLoss(diffOne ** 2), unewLeverage);
    const collateralDeposited = props.stakedBalance / props.stakedBalanceMultiplier;
    const changeCollateralValue =
      ((collateralDeposited * unewLeverage) / props.currentLeverage - collateralDeposited) * adjustFactorOne;
    const valueForEach = changeCollateralValue / 2;
    let bAmountA = priceA > 0 ? valueForEach / priceA : 0;
    let bAmountB = priceB > 0 ? valueForEach / priceB : 0;
    let cAmount = changeCollateralValue ?? 0;
    if (bAmountA < 0) {
      bAmountA = 0;
    }
    if (bAmountB < 0) {
      bAmountB = 0;
    }
    if (cAmount < 0) {
      cAmount = 0;
    }
    const changeAmounts = {
      bAmountA,
      bAmountB,
      cAmount,
      bAmountAMin: bAmountA / (1 + maxSlippage / 100),
      bAmountBMin: bAmountB / (1 + maxSlippage / 100),
      cAmountMin: cAmount / Math.sqrt(1 + maxSlippage / 100),
    };

    setNewLeverage(unewLeverage);
    setAmountADesired(changeAmounts.bAmountA);
    setAmountBDesired(changeAmounts.bAmountB);
    setAmountAMin(changeAmounts.bAmountAMin);
    setAmountBMin(changeAmounts.bAmountBMin);

    const borrowApr0 = Number(props.borrowable0?.borrowRate) * 365.25 * 24 * 60 * 60 * 100 || 0;
    const borrowApr1 = Number(props.borrowable1?.borrowRate) * 365.25 * 24 * 60 * 60 * 100 || 0;
    const newDebt0Usd = changeAmounts.bAmountA * props.borrowable0.underlying.derivedUSD;
    const newDebt1Usd = changeAmounts.bAmountB * props.borrowable1.underlying.derivedUSD;
    const interest0Usd = (props.debt0Usd + newDebt0Usd) * (borrowApr0 / 100);
    const interest1Usd = (props.debt1Usd + newDebt1Usd) * (borrowApr1 / 100);
    const totalInterest = interest0Usd + interest1Usd;
    let borrowApr;
    if (totalInterest > 0) {
      borrowApr = totalInterest / props.lpEquityUsd;
    }

    const leveragedFarmApr = unewLeverage * (props.farmApr / 100);
    const totalApr = leveragedFarmApr - (borrowApr ? borrowApr : 0);
    setBorrowApr(borrowApr ? borrowApr : -1);
    setLeveragedFarmApr(leveragedFarmApr);
    setTotalApr(totalApr);

    const uliquidationPrices = getLiquidationPrices(
      collateralDeposited,
      Number(props.lpStat?.token0DenomLPPrice),
      Number(props.lpStat?.token1DenomLPPrice),
      props.debt0,
      props.debt1,
      Number(props.lpStat?.twap),
      Number(props.collateral.safetyMargin),
      Number(props.collateral.liquidationIncentive),
      {
        changeCollateral: 0,
        changeBorrowedA: 0,
        changeBorrowedB: 0,
      },
    );
    setLiquidationPrices(uliquidationPrices);

    const changes = {
      changeCollateral: changeAmounts.cAmount,
      changeBorrowedA: changeAmounts.bAmountA,
      changeBorrowedB: changeAmounts.bAmountB,
    };
    const unewLiquidationPrices = getLiquidationPrices(
      collateralDeposited,
      Number(props.lpStat?.token0DenomLPPrice),
      Number(props.lpStat?.token1DenomLPPrice),
      props.debt0,
      props.debt1,
      Number(props.lpStat?.twap),
      Number(props.collateral.safetyMargin),
      Number(props.collateral.liquidationIncentive),
      changes,
    );
    setNewLiquidationPrices(unewLiquidationPrices);
  }

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      props.onRequestClose(false);
    }
  }, []);

  useEffect(() => {
    const elem = document.getElementById(props.amountElementId);
    if (elem) {
      // @ts-ignore
      updateNewLeverage(elem);
    }
  }, [props.stakedBalance, props.stakedDebt]);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  const sliderRange = [
    1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10,
  ].filter((v) => v > props.currentLeverage);
  sliderRange.unshift(props.currentLeverage);

  return (
    <>
      {props.isOpen ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none dark:bg-dark2-500">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t dark:text-light">
                  <h3 className="text-3xl font-semibold">{props.title}</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => props.onRequestClose(false)}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none dark:text-light">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <div className="my-4 text-slate-500 leading-relaxed dark:text-light">
                    <div>New Leverage:</div>
                    {roundAndFormatNumber(props.currentLeverage, 2)}x &nbsp;
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-6 h-6"
                      style={{ display: 'inline' }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      ></path>
                    </svg>
                    &nbsp;
                    {roundAndFormatNumber(newLeverage, 2)}x
                  </div>
                  <div className="my-4 text-slate-500 leading-relaxed dark:text-light">
                    <div>New Liquidation Prices:</div>
                    {props.lpStat?.ratio
                      ? roundAndFormatNumber(liquidationPrices[0] * props.lpStat?.ratio, 4) +
                        ' - ' +
                        roundAndFormatNumber(liquidationPrices[1] * props.lpStat?.ratio, 4)
                      : ''}
                    &nbsp;
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="w-6 h-6"
                      style={{ display: 'inline' }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      ></path>
                    </svg>
                    &nbsp;
                    {props.lpStat?.ratio
                      ? roundAndFormatNumber(newLiquidationPrices[0] * props.lpStat?.ratio, 4) +
                        ' - ' +
                        roundAndFormatNumber(newLiquidationPrices[1] * props.lpStat?.ratio, 4)
                      : ''}
                  </div>
                  {/*<MiniPrice
                                        icon={iconStakedUsd}
                                        iconAlt="pie chart"
                                        title={'Max leverage'}
                                        value={balance}
                                        maxOnClick={() => {
                                            const elem = document.getElementById(props.amountElementId) as HTMLInputElement;
                                            elem.value = String(balance);
                                            updateNewLeverage(elem);
                                        }}
                                    />*/}
                  <p style={{ lineHeight: '5px' }}>&nbsp;</p>
                  <div className="flex justify-evenly w-full gap-4 flex-wrap">
                    <MiniEditablePrice
                      icon={iconLock}
                      min={props.currentLeverage}
                      type="number"
                      iconAlt="lock"
                      title={`Enter New Leverage`}
                      value={round(newLeverage, 2)}
                      elementId={props.amountElementId}
                      onChange={(val) => {
                        const elem = val.currentTarget;
                        updateNewLeverage(elem);
                      }}
                      label={props.title}
                    />
                  </div>
                  <p style={{ lineHeight: '5px' }}>&nbsp;</p>
                  <div className="flex">
                    <InputSlider
                      percent={newLeverage}
                      setPercent={(value) => {
                        setNewLeverage(value);
                        updateStats(value);
                      }}
                      className="w-full"
                      range={sliderRange}
                      step={0.5}
                    />
                  </div>
                  <p className="border-t w-full" style={{ lineHeight: '5px' }}>
                    &nbsp;
                  </p>
                  <div className="my-4 text-slate-500 text-lg leading-relaxed dark:text-light">Borrow at most:</div>
                  <div className="flex justify-evenly w-full gap-4 mb-4 flex-wrap">
                    <MiniPrice
                      icon={iconStakedUsd}
                      iconAlt="pie chart"
                      title={props.borrowable0.underlying.symbol}
                      value={roundAndFormatNumber(amountADesired, 4)}
                    />
                    <MiniPrice
                      icon={iconStaked}
                      iconAlt="lock"
                      title={props.borrowable1.underlying.symbol}
                      value={roundAndFormatNumber(amountBDesired, 4)}
                      right
                    />
                  </div>
                  <p className="border-t w-full" style={{ lineHeight: '5px' }}>
                    &nbsp;
                  </p>
                  <div className="my-4 text-slate-500 text-lg leading-relaxed dark:text-light">Rewards:</div>
                  <div className="flex justify-evenly w-full gap-4 mb-4 flex-wrap">
                    <MiniPrice
                      icon={iconPieChart}
                      iconAlt="pie chart"
                      title={'Farm APY'}
                      value={roundAndFormatNumber(leveragedFarmApr * 100, 2) + '%'}
                    />
                    <MiniPrice
                      icon={iconPieChart}
                      iconAlt="pie chart"
                      title={`Borrow APY`}
                      textColor={'lightcoral'}
                      value={'-' + roundAndFormatNumber(borrowApr * 100, 2) + '%'}
                      right
                    />
                  </div>
                  <div className="flex justify-evenly w-full gap-4 mb-4 flex-wrap text-center">
                    <MiniPrice
                      icon={iconPieChart}
                      iconAlt="pie chart"
                      title={'Total APY'}
                      value={roundAndFormatNumber(totalApr * 100, 2)}
                    />
                  </div>
                  <p className="border-t w-full" style={{ lineHeight: '5px' }}>
                    &nbsp;
                  </p>
                  <p className="my-4 text-slate-500 leading-relaxed dark:text-light">
                    Max slippage: {roundAndFormatNumber(maxSlippage, 2)}%
                  </p>
                </div>
                {/* approve */}
                {(props.borrowAllowance0 == 0 || props.borrowAllowance1 == 0) && (
                  <div className="flex gap-3 items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    {props.borrowAllowance0 == 0 ? (
                      <button
                        className="flex-1 rounded-full bg-gradient-to-b from-[#fee22e] to-[#fed647] text-dark uppercase px-5 py-2 font-semibold drop-shadow-[0px_4px_5px_rgba(0,0,0,0.25)] hover:bg-[#fee22e] focus:outline-none focus:ring focus:ring-[#fee22e] focus:ring-opacity-75 whitespace-nowrap text-black"
                        onClick={() => props.borrowApproveFunction(props.borrowable0.id)}
                      >
                        Approve {props.borrowable0.underlying.symbol}
                      </button>
                    ) : null}
                    {props.borrowAllowance1 == 0 ? (
                      <button
                        className="flex-1 rounded-full bg-gradient-to-b from-[#fee22e] to-[#fed647] text-dark uppercase px-5 py-2 font-semibold drop-shadow-[0px_4px_5px_rgba(0,0,0,0.25)] hover:bg-[#fee22e] focus:outline-none focus:ring focus:ring-[#fee22e] focus:ring-opacity-75 whitespace-nowrap text-black"
                        onClick={() => props.borrowApproveFunction(props.borrowable1.id)}
                      >
                        Approve {props.borrowable1.underlying.symbol}
                      </button>
                    ) : null}
                  </div>
                )}
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="flex-1 rounded-full bg-gradient-to-b from-[#fee22e] to-[#fed647] text-dark uppercase px-5 py-2 font-semibold drop-shadow-[0px_4px_5px_rgba(0,0,0,0.25)] hover:bg-[#fee22e] focus:outline-none focus:ring focus:ring-[#fee22e] focus:ring-opacity-75 whitespace-nowrap  text-black"
                    onClick={() => {
                      if (newLeverage <= props.currentLeverage) {
                        alert('New leverage needs to be greater than the current one.');
                        return;
                      }
                      if (amountADesired > Number(props.borrowable0.totalBalance)) {
                        alert('Amount of ' + props.borrowable0.underlying.symbol + ' exceeds max available.');
                        return;
                      }
                      if (amountBDesired > Number(props.borrowable1.totalBalance)) {
                        alert('Amount of ' + props.borrowable1.underlying.symbol + ' exceeds max available.');
                        return;
                      }
                      props.leverageFunction(amountADesired, amountBDesired, amountAMin, amountBMin);
                    }}
                  >
                    {props.title}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default LeverageModal;
