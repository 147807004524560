export function log(source: string, content: any) {
    var debug = true;
    if (debug == true) {
        if (typeof content == typeof "" || typeof content == typeof "0") {
            console.log("[" + source + "]  " + content);
        }
        else {
            console.log("[" + source + "]  " + JSON.stringify(content));
        }
    }
}

export function round(value: number, precision: number) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.floor(value * multiplier) / multiplier;
}

export function formatNumber(inputNumber: number): string {
    var ret = inputNumber.toString();
    var expr = /\B(?=(\d{3})+(?!\d))/g;
    if (ret.indexOf(".") == -1) { ret = ret.replace(expr, ","); }
    else {
        var split = ret.split(".");
        var left = split[0].replace(expr, ",");
        var right = split[1];
        if (right.length == 1 && right != "0") { right += "0"; }

        ret = `${left}.${right}`;
    }

    return ret;
}

export function roundAndFormatNumber(value: number, precision: number = 2, abbreviate: boolean = false): string {
    var ret = "";
    var input = value;
    var suffix = "";

    if (abbreviate == true) {
        if (input >= 1000000000) {
            suffix = "B";
            input /= 1000000000;
        }
        else if (input >= 1000000) {
            suffix = "M";
            input /= 1000000;
        }
        else if (input >= 1000) {
            suffix = "K";
            input /= 1000;
        }
    }

    var rounded = round(input, precision);
    var formatted = formatNumber(rounded);
    ret = formatted + suffix;
    return ret;
}

export function convertFromUnixTimestamp(input: number): Date {
    return new Date(input * 1000);
}

export function getYearMonthDayString(date: Date): string {
    var ret = date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, '0') + "-" + date.getDate().toString().padStart(2, '0');
    return ret;
}

export function getDayMonthFullString(date: Date): string {
    var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var ret = `${date.getDate().toString().padStart(2, '0')} ${months[date.getMonth()]}`;
    return ret;
}

export function getYearMonthDayTimeString(date: Date): string {
    var suffix = date.getHours() > 12 ? "PM" : "AM";

    var ret = date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, '0') + "-" + date.getDate().toString().padStart(2, '0') + " " + (date.getHours() % 12).toString().padStart(2, '0') + ":" + date.getMinutes().toString().padStart(2, '0') + " " + suffix;
    return ret;
}

export function addDaysToDate(date: Date, days: number): Date {
    var ret = new Date(date);
    ret.setDate(date.getDate() + days);
    return ret;
}

export function getDurationSeconds(start: Date, end: Date): number {
    var diff = end.valueOf() - start.valueOf() / 1000;
    return diff;
}

//https://bearnithi.com/2019/11/10/how-to-calculate-the-time-difference-days-hours-minutes-between-two-dates-in-javascript/#:~:text=To%20calculate%20the%20minutes%20difference,milliseconds%20(minutes%20*%2060)%20.
export function getTimeUntil(start: Date, end: Date) {
    let diffInMilliSeconds = (end.valueOf() - start.valueOf()) / 1000;

    var ret = { days: 0, hours: 0, minutes: 0, hasEnded: true };
    if (diffInMilliSeconds > 0) {
        // calculate days
        const days = Math.floor(diffInMilliSeconds / 86400);
        diffInMilliSeconds -= days * 86400;

        // calculate hours
        const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
        diffInMilliSeconds -= hours * 3600;

        // calculate minutes
        const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
        diffInMilliSeconds -= minutes * 60;

        ret = { days: days, hours: hours, minutes: minutes, hasEnded: false };
    }

    return ret;
}

export function abbreviateAddress(walletAddress: string, beforeLength: number = 6, afterLength: number = 4): string {
    return walletAddress.substring(0, beforeLength) + "..." + walletAddress.substring(walletAddress.length - afterLength, walletAddress.length);
}

export class Group<T> {
    key: string;
    members: T[] = [];
    constructor(key: string) {
        this.key = key;
    }
}

export function groupBy<T>(list: T[], func: (x: T) => string): Group<T>[] {
    let res: Group<T>[] = [];
    let group: Group<T> | null = null;
    list.forEach((o) => {
        let groupName = func(o);
        if (group === null) {
            group = new Group<T>(groupName);
        }
        if (groupName != group.key) {
            res.push(group);
            group = new Group<T>(groupName);
        }
        group.members.push(o)
    });
    if (group != null) {
        res.push(group);
    }
    return res
}

// Impermax functions
const getValuesFromPrice = (
    collateralDeposited: number,
    priceA: number,
    priceB: number,
    tokenABorrowed: number,
    tokenBBorrowed: number,
    changes: Changes = {
        changeCollateral: 0,
        changeBorrowedA: 0,
        changeBorrowedB: 0
    }
): {
    valueCollateral: number;
    valueA: number;
    valueB: number;
} => {
    const valueCollateralCandidate = collateralDeposited + changes.changeCollateral;
    const amountA = tokenABorrowed + changes.changeBorrowedA;
    const amountB = tokenBBorrowed + changes.changeBorrowedB;
    const valueACandidate = amountA * priceA;
    const valueBCandidate = amountB * priceB;

    return {
        valueCollateral: valueCollateralCandidate > 0 ? valueCollateralCandidate : 0,
        valueA: valueACandidate > 0 ? valueACandidate : 0,
        valueB: valueBCandidate > 0 ? valueBCandidate : 0
    };
};

// Impermax function
export interface Changes {
    changeBorrowedA: number;
    changeBorrowedB: number;
    changeCollateral: number;
}
export const getLiquidationPrices = (
    collateralDeposited: number,
    tokenADenomLPPrice: number,
    tokenBDenomLPPrice: number,
    tokenABorrowed: number,
    tokenBBorrowed: number,
    twapPrice: number,
    safetyMargin: number,
    liquidationIncentive: number,
    changes: Changes = {
        changeCollateral: 0,
        changeBorrowedA: 0,
        changeBorrowedB: 0
    }
): [
    number,
    number
] => {
    const {
        valueCollateral,
        valueA,
        valueB
    } = getValuesFromPrice(
        collateralDeposited,
        tokenADenomLPPrice,
        tokenBDenomLPPrice,
        tokenABorrowed,
        tokenBBorrowed,
        changes
    );

    let tokenAPriceSwing;
    let tokenBPriceSwing;
    if (valueA + valueB === 0) {
        tokenAPriceSwing = Infinity;
        tokenBPriceSwing = Infinity;
    }
    const actualCollateral = valueCollateral / liquidationIncentive;
    const rad = Math.sqrt(actualCollateral ** 2 - 4 * valueA * valueB);
    if (!rad) {
        tokenAPriceSwing = 0;
        tokenBPriceSwing = 0;
    }
    const t = (actualCollateral + rad) / (2 * Math.sqrt(safetyMargin));
    tokenAPriceSwing = (t / valueA) ** 2;
    tokenBPriceSwing = (t / valueB) ** 2;

    // ray test touch <<
    // TODO: this.priceInverted === true
    // const price0 = twapPrice / tokenAPriceSwing;
    // const price1 = twapPrice * tokenBPriceSwing;
    // ray test touch >>
    const price0 = twapPrice / tokenBPriceSwing;
    const price1 = twapPrice * tokenAPriceSwing;

    return [
        price0,
        price1
    ];
};

export const getLeverage = (
    collateralDeposited: number,
    tokenADenomLPPrice: number,
    tokenBDenomLPPrice: number,
    tokenABorrowed: number,
    tokenBBorrowed: number,
    changes: Changes = {
        changeBorrowedA: 0,
        changeBorrowedB: 0,
        changeCollateral: 0
    }
): number => {
    const {
        valueCollateral,
        valueA,
        valueB
    } = getValuesFromPrice(
        collateralDeposited,
        tokenADenomLPPrice,
        tokenBDenomLPPrice,
        tokenABorrowed,
        tokenBBorrowed,
        changes
    );

    const valueDebt = valueA + valueB;
    if (valueDebt === 0) return 1;

    const equity = valueCollateral - valueDebt;
    if (equity <= 0) return Infinity;

    return valueDebt / equity + 1;
};

export const getMaxDeleverage = (
    collateralDeposited: number,
    tokenAMarketDenomLPPrice: number,
    tokenBMarketDenomLPPrice: number,
    tokenABorrowed: number,
    tokenBBorrowed: number,
    slippage: number
): number => {
    const {
        valueCollateral,
        valueA,
        valueB
    } = getValuesFromPrice(
        collateralDeposited,
        tokenAMarketDenomLPPrice,
        tokenBMarketDenomLPPrice,
        tokenABorrowed,
        tokenBBorrowed
    );
    const minRepayPerSide = valueCollateral / 2 / Math.sqrt(1 + slippage / 100);
    let maxDeleverage;
    if (minRepayPerSide >= valueA && minRepayPerSide >= valueB) {
        maxDeleverage = collateralDeposited;
    }
    if (minRepayPerSide * 2 < valueA + valueB) {
        maxDeleverage = 0;
    }
    maxDeleverage = Math.min(valueA, valueB) * 2 * Math.sqrt(1 + slippage / 100);

    return maxDeleverage;
};

export const getImpermanentLoss = (priceSwing: number): number => {
    return Math.sqrt(priceSwing) / (priceSwing + 1) * 2;
};
