import React, { FC, useContext, useEffect, useState } from "react";

import Nav from "./Nav";
import pageBackground from "../Images/background.png";
import pageBackgroundDark from "../Images/background-dark.png";
import SummaryPanel from "./SummaryPanel";
import { Web3Context } from "../Components/Web3Context/Web3Context";

const Layout: FC = (props) => {
    const [collapsedSidebar, setCollapsedSidebar] = useState(true);
    const [darkMode, setDarkMode] = useState(
        localStorage.theme === "dark" ||
            (!("theme" in localStorage) && window.matchMedia("(prefers-color-scheme: dark)").matches)
            ? true
            : false
    );

    useEffect(() => {
        if (darkMode) {
            localStorage.theme = "dark";
            document.documentElement.classList.add("dark");
        } else {
            localStorage.theme = "light";
            document.documentElement.classList.remove("dark");
        }
    }, [darkMode]);

    const { isAdmin, } = useContext(Web3Context);

    return (
        <div className="flex flex-1 bg-secondary-500 dark:bg-dark1-500 flex-col md:flex-row md:h-full">
            <Nav
                setNavCollapsed={setCollapsedSidebar}
                sideBarCollapsed={collapsedSidebar}
                darkMode={darkMode}
                setDarkMode={setDarkMode}
                isAdmin={isAdmin}
            />
            <div
                className={
                    "min-h-full bg-no-repeat h-auto w-full bg-cover bg-center sm:flex flex-col w-full flex-1 py-6 overflow-y-auto overflow-x-hidden scrollbar scrollbar-thin scrollbar-thumb-rounded-full scrollbar-thumb-slate-700 dark:scrollbar-thumb-slate-300"
                }
                style={{ backgroundImage: `url('${darkMode ? pageBackgroundDark : pageBackground}')` }}
            >
                <div className="sm:flex h-full flex-col w-100 px-6 md:mx-16 self-center max-w-7xl w-full md:px-16">
                    {props.children}
                </div>
            </div>
            <SummaryPanel className="min-h-full bg-primary-500 dark:bg-[#46428f] w-full md:w-fit px-3 pt-3 pb-8 md:px-6 md:py-6 flex flex-col items-center drop-shadow-md lg:min-w-[366px] overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-rounded-full scrollbar-thumb-slate-700 dark:scrollbar-thumb-slate-300" />
        </div>
    );
};

export default Layout;
