import SocialBar from "../Components/Social/SocialBar";
import PortfolioCard from "../Cards/PortfolioCard";

function PortfolioPage() {
    return (
        <main className="flex flex-col w-100 flex-1 relative focus:outline-none dark:text-light">
            <h1 className="text-black-500 text-left text-2xl sm:text-3xl md:text-4xl font-semibold mb-4">
                Keep track of your BOMB investments{" "}
                <span className="italic font-extrabold whitespace-nowrap">
                    <span className="text-primary-500">//</span> Bombfolio <span className="text-primary-500">//</span>
                </span>
            </h1>
            <div className="max-w-lg lg:max-w-none rounded-lg flex-1 mt-2 md:mx-0 flex">
                <PortfolioCard />
            </div>
            <SocialBar className="mt-12" />
        </main>
    );
}

export default PortfolioPage;
