import React, { FC, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../Images/bombmax-512.png';
import iconHome from '../Images/home.png';
import iconStake from '../Images/stake.png';
import iconFarm from '../Images/farm.png';
import iconBorrow from '../Images/borrow.png';
import iconPortfolio from '../Images/portfolio.png';
import iconDay from '../Images/day.png';
import iconNight from '../Images/night.png';
import { MenuIcon, ShieldCheckIcon, TerminalIcon } from '@heroicons/react/solid';

interface NavProps {
  sideBarCollapsed: boolean;
  setNavCollapsed: (bool: boolean) => void;
  darkMode: boolean;
  setDarkMode: (bool: boolean) => void;
  isAdmin: boolean;
}

interface NavItem {
  name: string;
  url: string;
  icon: JSX.Element;
  admin: boolean;
}

const Nav: FC<NavProps> = (props) => {
  const location = useLocation();
  const pathName = location.pathname;

  const isSelected = (path: string) => {
    if (path === '/' && pathName === '/') {
      return true;
    } else if (pathName.startsWith(path) && path !== '/') {
      return true;
    } else {
      return false;
    }
  };
  const navItems: NavItem[] = [
    {
      name: 'Home',
      url: '/home',
      icon: <img src={iconHome} alt="home" />,
      admin: false,
    },
    {
      name: 'Stake',
      url: '/stake',
      icon: <img src={iconStake} alt="stake" />,
      admin: false,
    },
    {
      name: 'Farm',
      url: '/farm',
      icon: <img src={iconFarm} alt="farm" />,
      admin: false,
    },
    {
      name: 'Borrow',
      url: '/borrow',
      icon: <img src={iconBorrow} alt="borrow" />,
      admin: false,
    },
    /*{
            name: "Portfolio",
            url: "/portfolio",
            icon: <img src={iconPortfolio} alt="portfolio" />,
            admin: false
        },*/
    {
      name: 'Debug',
      url: '/debug',
      icon: <TerminalIcon />,
      admin: true,
    },
    {
      name: 'Admin',
      url: '/admin',
      icon: <ShieldCheckIcon />,
      admin: true,
    },
  ];
  const handleNavigate = () => {
    props.setNavCollapsed(true);
  };

  return (
    <div className="bg-primary-500 h-100 flex flex-col dark:bg-[#46428f] ">
      <div className="md:mb-4 md:place-self-center flex justify-between w-full items-center place-content-center">
        <Link to="/" onClick={handleNavigate} className="w-12 h-12 md:w-16 md:h-16 flex md:mx-auto">
          <img src={logo} alt="BOMB Logo" className="w-full h-full z-50 relative" />
        </Link>
        <MenuIcon
          className="block md:hidden h-10 w-10"
          onClick={() => props.setNavCollapsed(!props.sideBarCollapsed)}
        />
      </div>
      <nav
        className={
          'md:w-20 md:h-full overflow-auto md:overflow-visible transition-all flex flex-col' +
          (props.sideBarCollapsed ? ' h-0' : ' h-[100%]')
        }
      >
        {navItems
          .filter((x) => x.admin == false || (x.admin == true && props.isAdmin == true))
          .map((x) => {
            const selected = isSelected(x.url);
            return (
              <div key={x.url} className="py-2">
                <div className="flex relative">
                  {selected && (
                    <>
                      <div className="hidden md:block absolute bg-primary-500 right-0 top-[-2rem] rounded-full w-8 h-8 z-20 dark:bg-[#46428f]"></div>
                      <div className="hidden md:block absolute bg-secondary-500 dark:bg-dark1-500 right-[-1px] top-[-1rem] w-4 h-5 z-10 dark:border-r-[2px] dark:border-dark1-500"></div>
                      <div className="hidden md:block absolute bg-primary-500 right-0 bottom-[-2rem] rounded-full w-8 h-8 z-20 dark:bg-[#46428f]"></div>
                      <div className="hidden md:block absolute bg-secondary-500 dark:bg-dark1-500 right-[-1px] bottom-[-1rem] w-4 h-5 z-10 dark:border-r-[2px] dark:border-dark1-500"></div>
                    </>
                  )}
                  <div
                    className={
                      'flex place-items-center rounded-full md:mx-auto py-2 mx-4 px-4' +
                      (selected
                        ? ' bg-secondary-500 dark:bg-dark1-500 dark:md:bg-transparent dark:md:bg-[linear-gradient(90deg,#232428_70px,transparent_50%)] md:pl-4 md:translate-x-[0.7rem]'
                        : '')
                    }
                  >
                    <Link
                      to={x.url}
                      className={
                        'h-7 w-7 flex-1 z-50 flex' +
                        (selected
                          ? ' dark:brightness-0 dark:invert'
                          : ' md:opacity-20 hover:md:opacity-50 dark:md:opacity-70 dark:hover:md:opacity-100')
                      }
                      onClick={handleNavigate}
                      title={x.name}
                    >
                      {x.icon}
                    </Link>
                    <Link
                      to={x.url}
                      className={'pl-2 text-md font-bold dark:text-white' + (selected ? '' : ' md:hidden')}
                      onClick={handleNavigate}
                    >
                      {x.name}
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        <div className="flex items-center justify-center w-full mb-6 mt-auto">
          <label htmlFor="toggleB" className="flex flex-col items-center cursor-pointer">
              <div className="text-white font-bold pb-2">
                <a href="https://bombmoney.gitbook.io/bomb-max/" onClick={handleNavigate} className="">
                    Docs
                </a>
              </div>
            <div className="relative">
              <input
                type="checkbox"
                id="toggleB"
                className="sr-only peer"
                checked={props.darkMode}
                onChange={() => props.setDarkMode(!props.darkMode)}
              />
              <div
                className={
                  'w-14 h-6 rounded-full bg-gradient-to-l border-[1px]' +
                  (props.darkMode
                    ? ' from-[#616166] to-[#0f0014] border-[#4b404e]'
                    : ' from-white to-[#edf0f5] border-[#ededed]')
                }
              >
                <span
                  className={
                    'text-[0.5rem] font-bold absolute top-0 bottom-0 my-auto flex items-center mx-1' +
                    (props.darkMode ? ' left-[1px] text-white' : ' right-1 text-[#d0d0d0]')
                  }
                >
                  {props.darkMode ? 'NIGHT' : 'DAY'}
                </span>
              </div>
              <div className="absolute my-auto top-0 bottom-0 left-[2px] right-0 bg-white w-5 h-5 rounded-full transition peer-checked:translate-x-[31px] shadow-lg bg-gradient-to-br from-white to-[#edf0f5] border-[2px] border-white">
                <div
                  className="bg-75 bg-no-repeat bg-center w-full h-full"
                  style={{ backgroundImage: `url('${props.darkMode ? iconNight : iconDay}')` }}
                ></div>
              </div>
            </div>
          </label>
        </div>
      </nav>
    </div>
  );
};

export default Nav;
