import { FC } from 'react';
import { Link } from 'react-router-dom';
import iconLock from '../Images/lock.png';
import iconPieChart from '../Images/pie-chart.png';
import imgCircle from '../Images/circle.png';
import imgTriangle from '../Images/triangle.png';
import imgDiamond from '../Images/diamond.png';
import MiniPrice from '../Components/CardControls/MiniPrice';
import RiskGraph, { RiskType } from '../Components/CardControls/RiskGraph';
import { roundAndFormatNumber } from '../Common/HelperFunctions';

interface RiskCardProps {
  className?: string;
  featured?: boolean;
  title: string;
  action: string;
  explanation: string;
  buttonText: string;
  buttonLink: string;
  investmentType: InvestmentType;

  tvl: number;
  apr: number;
}

export enum InvestmentType {
  STAKE,
  FARM,
  BORROW,
}

interface RiskProperties {
  shape: string;
  riskType: RiskType;
}

const RiskCard: FC<RiskCardProps> = (props) => {
  const buildRiskProperties = () => {
    switch (props.investmentType) {
      case InvestmentType.STAKE:
        return {
          shape: imgCircle,
          riskType: RiskType.LOW,
        } as RiskProperties;
      case InvestmentType.FARM:
        return {
          shape: imgTriangle,
          riskType: RiskType.MEDIUM,
        } as RiskProperties;
      case InvestmentType.BORROW:
        return {
          shape: imgDiamond,
          riskType: RiskType.HIGH,
        } as RiskProperties;
    }
  };
  const riskProperties = buildRiskProperties();
  return (
    <div
      className={
        'py-6 px-8 overflow-hidden bg-white card relative shadow rounded-xl text-left flex flex-col text-gray-800 max-w-fit dark:text-light' +
        (props.featured
          ? ' p-0.5 bg-gradient-to-tr from-yellow-500 via-red-500 to-indigo-700 '
          : ' bg-white dark:bg-dark2-500') +
        (props.className ? ` ${props.className}` : '')
      }
    >
      <div className="flex flex-col items-center">
        <div className="font-extrabold text-2xl items-center flex gap-2 font-urbanist mb-4 whitespace-nowrap dark:text-light">
          {props.title}
          <RiskGraph riskType={riskProperties.riskType} />
        </div>
        <div className="mb-6 w-full max-w-[8rem] md:max-w-[12rem]">
          <div className="w-full pt-[100%]  flex bg-gradient-to-r from-[#DFE0E5] to-white via-[#EDECF1] rounded-full drop-shadow-lg relative dark:bg-gradient-to-b dark:from-[#32343d] dark:to-[#6d6f79]">
            <div className="w-[85%] h-[85%] bg-gradient-to-br from-[#FFF] to-[#E4E4E6] rounded-full m-auto inset-0 absolute dark:bg-gradient-to-b dark:from-[#6d6f79] dark:to-[#32343d]">
              <div className="w-[95%] h-[95%] bg-gradient-to-r from-[#DFE0E5] to-white rounded-full drop-shadow-lg m-auto inset-0 absolute dark:bg-gradient-to-b dark:from-[#32343d] dark:to-[#6d6f79]">
                <div
                  className={
                    'bg-no-repeat h-full w-full bg-contain bg-center' +
                    (props.investmentType === InvestmentType.FARM ? ' absolute top-[-10px]' : '')
                  }
                  style={{ backgroundImage: `url('${riskProperties.shape}')` }}
                >
                  <div
                    className={
                      'font-extrabold text-xl flex items-center h-full justify-center font-urbanist uppercase dark:text-light' +
                      (props.investmentType === InvestmentType.FARM
                        ? ' absolute top-[10px] mx-auto left-0 right-0'
                        : '')
                    }
                  >
                    {props.action}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-evenly w-full gap-8 mb-6 flex-wrap">
          <MiniPrice
            icon={iconPieChart}
            iconAlt="pie chart"
            title="MAX APY"
            value={roundAndFormatNumber(props.apr, 2, true) + '%'}
          />
          <MiniPrice
            icon={iconLock}
            iconAlt="lock"
            title="TVL"
            value={'$' + roundAndFormatNumber(props.tvl, 2, true)}
            right
          />
        </div>
        <div className="mb-2">
          <Link
            className=" flex rounded-full bg-gradient-to-b from-[#f8cd24] to-[#fed647] uppercase px-5 py-2 font-semibold drop-shadow-[0px_4px_5px_rgba(0,0,0,0.25)] dark:text-black"
            to={props.buttonLink}
          >
            <div className="">{props.buttonText}</div>
          </Link>
        </div>
        <div className="text-center leading-relaxed mt-2 mb-6 text-sm dark:text-light">{props.explanation}</div>
      </div>
    </div>
  );
};

export default RiskCard;
