import { FC } from "react";
import moment from "moment";
import { Line, LineChart, CartesianGrid, Label, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

interface PortfolioCardProps {
    className?: string;
    featured?: boolean;
}

const PortfolioCard: FC<PortfolioCardProps> = (props) => {
    let data = [
        {
            date: "2022-01-01",
            stake: 100,
            farm: 100,
            borrow: 100,
        },
        {
            date: "2022-01-02",
            stake: 300,
            farm: 400,
            borrow: 500,
        },
        {
            date: "2022-01-03",
            stake: 600,
            farm: 700,
            borrow: 800,
        },
        {
            date: "2022-01-04",
            stake: 700,
            farm: 800,
            borrow: 1000,
        },
    ];

    //shows a flat graph if there is only 1 result
    if (data.length === 1) {
        data.push(data[0]);
    }
    const dateFormatter = (date: string) => {
        if (!date || date === "auto") return "";
        return moment(date).format("DD-MMM");
    };

    const CustomTooltip = ({ active, payload }: any) => {
        if (active && payload && payload.length) {
            const pay = payload[0]?.payload;
            return (
                <div className="text-sm">
                    <div>{pay.date}</div>
                    <div>
                        YBBTC: $
                        {pay.pv.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                        })}
                    </div>
                    <div>
                        BTC: $
                        {pay.uv.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                        })}
                    </div>
                </div>
            );
        }
        return null;
    };

    return (
        <div
            className={
                "px-6 pb-6 pt-3 overflow-hidden bg-white card relative shadow rounded-xl text-left flex flex-col text-gray-800 min-w-full md:min-w-fit w-[22rem] flex-1 dark:text-light" +
                (props.featured
                    ? " p-0.5 bg-gradient-to-tr from-yellow-500 via-red-500 to-indigo-700 "
                    : " bg-white dark:bg-dark2-500") +
                (props.className ? ` ${props.className}` : "")
            }
        >
            <div className="w-full h-full relative">
                <ResponsiveContainer width="99%" height="100%" className="min-h-[15rem]">
                    <LineChart
                        data={data}
                        margin={{
                            top: 30,
                            right: 60,
                            left: 40,
                            bottom: 40,
                        }}
                    >
                        <CartesianGrid stroke={"#ccc"} baseFrequency="" />
                        {/* <Tooltip content={CustomTooltip} /> */}
                        <XAxis
                            dataKey="date"
                            tickMargin={0}
                            height={20}
                            tick={{ fill: "#A8B0BF" }}
                            tickFormatter={dateFormatter}
                        >
                            <Label
                                value="Date"
                                className="fill-[#434E65] dark:fill-light"
                                fontWeight="600"
                                fontSize={24}
                                position="bottom"
                                offset={20}
                                style={{ textAnchor: "middle" }}
                            />
                        </XAxis>
                        <YAxis tick={true} width={40}>
                            <Label
                                className="fill-[#434E65] dark:fill-light"
                                value="Value ($)"
                                fontWeight="600"
                                fontSize={24}
                                angle={-90}
                                position="left"
                                offset={15}
                                style={{ textAnchor: "middle" }}
                            />
                        </YAxis>
                        <Line
                            type="monotone"
                            dataKey="stake"
                            stroke="#FFD918"
                            strokeWidth={5}
                            strokeLinecap="round"
                        ></Line>
                        <Line
                            type="monotone"
                            dataKey="farm"
                            stroke="#ff9018"
                            strokeWidth={5}
                            strokeLinecap="round"
                        ></Line>
                        <Line
                            type="monotone"
                            dataKey="borrow"
                            stroke="#B00"
                            strokeWidth={5}
                            strokeLinecap="round"
                        ></Line>
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default PortfolioCard;
