import { FC } from "react";

interface RiskGraphProps {
    className?: string;
    riskType: RiskType;
}
export enum RiskType {
    LOW,
    MEDIUM,
    HIGH,
}
const RiskGraph: FC<RiskGraphProps> = (props) => {
    return (
        <div className={"inline-flex h-8 items-end gap-[3px] pb-2" + (props.className ? ` ${props.className}` : "")}>
            <div
                className={
                    "rounded-[1px] w-[0.4rem] h-[50%] m-0 p-0" +
                    (props.riskType === RiskType.LOW ||
                    props.riskType === RiskType.MEDIUM ||
                    props.riskType === RiskType.HIGH
                        ? " bg-primary-500"
                        : " bg-gray-400")
                }
            ></div>
            <div
                className={
                    "rounded-[1px] w-[0.4rem] h-[75%] m-0 p-0" +
                    (props.riskType === RiskType.MEDIUM || props.riskType === RiskType.HIGH
                        ? " bg-primary-500"
                        : " bg-gray-400")
                }
            ></div>
            <div
                className={
                    "rounded-[1px] w-[0.4rem] h-[100%] m-0 p-0" +
                    (props.riskType === RiskType.HIGH ? " bg-primary-500" : " bg-gray-400")
                }
            ></div>
        </div>
    );
};

export default RiskGraph;
