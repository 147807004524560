import React, { FC, useCallback, useEffect, useState } from "react";
import MiniEditablePrice from "../Components/CardControls/MiniEditablePrice";
import iconLock from "../Images/lock.png";
import { Collateral, Pair } from "../Common/Models/LendingGraph";
import { getLeverage, getLiquidationPrices, getMaxDeleverage, roundAndFormatNumber } from "../Common/HelperFunctions";
import MiniPrice from "../Components/CardControls/MiniPrice";
import iconStakedUsd from "../Images/staked-dollars-icon.png";
import { LpStat } from "../Common/Models/LPStat";

interface DeleverageModalProps {
    isOpen: boolean;
    title: string;
    deleverageFunction: Function;
    onRequestClose: Function;
    currentLeverage: number;
    amountElementId: string;
    stakedBalance: number;
    stakedBalanceMultiplier: number;
    allowance: number;
    collateral: Collateral;
    pair: Pair;
    lpStat: LpStat;
    debt0: number;
    debt1: number;
    approveFunction: (poolAsset: string) => void;
}

const DeleverageModal: FC<DeleverageModalProps> = (props) => {
    const [maxSlippage, setMaxSlippage] = useState<number>(2);
    const [lpsToRedeem, setlpsToRedeem] = useState<number>(0);
    const [amountAMin, setAmountAMin] = useState<number>(0);
    const [amountBMin, setAmountBMin] = useState<number>(0);
    const [liquidationPrices, setLiquidationPrices] = useState<number[]>([0, 0]);
    const [newLiquidationPrices, setNewLiquidationPrices] = useState<number[]>([0, 0]);
    const [maxDeleverage, setMaxDeleverage] = useState<number>(0);
    const [newLeverage, setNewLeverage] = useState<number>(0);

    async function updateLpsToReedem(elem: HTMLInputElement) {
        console.log(`Element: ${elem.id}   Value: ${elem.value}`);
        let ulpsToRedeem = elem.value === "" ? 0 : elem.valueAsNumber;

        const priceA = Number(props.pair.totalSupply) / Number(props.pair.reserve0) / 2;
        const priceB = Number(props.pair.totalSupply) / Number(props.pair.reserve1) / 2;
        const valueForEach = ulpsToRedeem / 2;
        //const bAmountA = priceA > 0 ? valueForEach / priceA : 0;
        //const bAmountB = priceB > 0 ? valueForEach / priceB : 0;
        const bAmountA = elem.valueAsNumber * props.lpStat.token0Amount;
        const bAmountB = elem.valueAsNumber * props.lpStat.token1Amount;
        const changeAmounts = {
            bAmountA,
            bAmountB,
            cAmount: ulpsToRedeem / props.stakedBalanceMultiplier,
            bAmountAMin: bAmountA / Math.sqrt(1 + maxSlippage / 100),
            bAmountBMin: bAmountB / Math.sqrt(1 + maxSlippage / 100)
        };

        setlpsToRedeem(ulpsToRedeem);
        setAmountAMin(changeAmounts.bAmountAMin);
        setAmountBMin(changeAmounts.bAmountBMin);

        const umaxDeleverage =
            getMaxDeleverage(
                props.stakedBalance / props.stakedBalanceMultiplier,
                priceA,
                priceB,
                props.debt0,
                props.debt1,
                maxSlippage
            );
        setMaxDeleverage(umaxDeleverage);

        const changes = {
            changeBorrowedA: -changeAmounts.bAmountA ?? 0,
            changeBorrowedB: -changeAmounts.bAmountB ?? 0,
            changeCollateral: -changeAmounts.cAmount ?? 0
        };

        const uliquidationPrices =
            getLiquidationPrices(
                props.stakedBalance / props.stakedBalanceMultiplier,
                Number(props.lpStat?.token0DenomLPPrice),
                Number(props.lpStat?.token1DenomLPPrice),
                props.debt0,
                props.debt1,
                Number(props.lpStat?.twap),
                Number(props.collateral.safetyMargin),
                Number(props.collateral.liquidationIncentive),
            );
        setLiquidationPrices(uliquidationPrices);
        const unewLiquidationPrices =
            getLiquidationPrices(
                props.stakedBalance / props.stakedBalanceMultiplier,
                Number(props.lpStat?.token0DenomLPPrice),
                Number(props.lpStat?.token1DenomLPPrice),
                props.debt0,
                props.debt1,
                Number(props.lpStat?.twap),
                Number(props.collateral.safetyMargin),
                Number(props.collateral.liquidationIncentive),
                changes
            );
        setNewLiquidationPrices(unewLiquidationPrices);
        const unewLeverage =
            getLeverage(
                props.stakedBalance / props.stakedBalanceMultiplier,
                Number(props.lpStat?.token0DenomLPPrice),
                Number(props.lpStat?.token1DenomLPPrice),
                props.debt0,
                props.debt1,
                changes
            );
        setNewLeverage(unewLeverage);
    }

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            props.onRequestClose(false)
        }
    }, []);

    useEffect(() => {
        const elem = document.getElementById(props.amountElementId);
        if (elem) {
            // @ts-ignore
            updateLpsToReedem(elem);
        }
    }, [props.stakedBalance]);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, []);

    return (
        <>
            {props.isOpen ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div
                                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none dark:bg-dark2-500">
                                {/*header*/}
                                <div
                                    className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t dark:text-light">
                                    <h3 className="text-3xl font-semibold">
                                        {props.title}
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => props.onRequestClose(false)}
                                    >
                    <span
                        className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none dark:text-light">
                      ×
                    </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className="my-4 text-slate-500 leading-relaxed dark:text-light">
                                        <div>New Leverage:</div>
                                        {roundAndFormatNumber(props.currentLeverage, 2)}x
                                        &nbsp;
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             stroke="currentColor" className="w-6 h-6" style={{"display": "inline"}}>
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                                        </svg>
                                        &nbsp;
                                        {roundAndFormatNumber(newLeverage, 2)}x
                                    </div>
                                    <div className="my-4 text-slate-500 leading-relaxed dark:text-light">
                                        <div>New Liquidation Prices:</div>
                                        {props.lpStat?.ratio ? (roundAndFormatNumber(liquidationPrices[0] * props.lpStat?.ratio, 4) + ' - ' + roundAndFormatNumber(liquidationPrices[1] * props.lpStat?.ratio, 4)) : ''}
                                        &nbsp;
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                             stroke="currentColor" className="w-6 h-6" style={{"display": "inline"}}>
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                  d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                                        </svg>
                                        &nbsp;
                                        {props.lpStat?.ratio ? (roundAndFormatNumber(newLiquidationPrices[0] * props.lpStat?.ratio, 4) + ' - ' + roundAndFormatNumber(newLiquidationPrices[1] * props.lpStat?.ratio, 4)) : ''}
                                    </div>
                                    <MiniPrice
                                        icon={iconStakedUsd}
                                        iconAlt="pie chart"
                                        title={props.stakedBalance ? 'Staked' : 'Wallet'}
                                        value={String(props.stakedBalance)}
                                        maxOnClick={() => {
                                            const elem = document.getElementById(props.amountElementId) as HTMLInputElement;
                                            elem.value = String(props.stakedBalance);
                                            updateLpsToReedem(elem);
                                        }}
                                    />
                                    <p style={{ 'lineHeight': '5px' }}>&nbsp;</p>
                                    <div className="flex justify-evenly w-full gap-4 flex-wrap">
                                        <MiniEditablePrice
                                            icon={iconLock}
                                            min={0}
                                            max={props.stakedBalance}
                                            type="number"
                                            iconAlt="lock"
                                            title={`LPs to redeem`}
                                            value={lpsToRedeem}
                                            elementId={props.amountElementId}
                                            onChange={(val) => {
                                                const elem = val.currentTarget;
                                                updateLpsToReedem(elem)
                                            }}
                                            label={props.title}
                                        />
                                    </div>
                                    <p style={{ 'lineHeight': '5px' }}>&nbsp;</p>
                                    <p className="my-4 text-slate-500 leading-relaxed dark:text-light">
                                        Max slippage: {roundAndFormatNumber(maxSlippage, 2)}%
                                    </p>
                                </div>
                                {/* approve */}
                                <div
                                    className="flex gap-3 items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    {props.allowance == 0 ? (
                                        <button
                                            className="flex-1 rounded-full bg-gradient-to-b from-[#fee22e] to-[#fed647] text-dark uppercase px-5 py-2 font-semibold drop-shadow-[0px_4px_5px_rgba(0,0,0,0.25)] hover:bg-[#fee22e] focus:outline-none focus:ring focus:ring-[#fee22e] focus:ring-opacity-75 whitespace-nowrap text-black"
                                            onClick={() => props.approveFunction(props.collateral.id)}
                                        >
                                            Approve
                                        </button>
                                    ) : (
                                        <button
                                            className="flex-1 rounded-full bg-gradient-to-b from-[#fee22e] to-[#fed647] text-dark uppercase px-5 py-2 font-semibold drop-shadow-[0px_4px_5px_rgba(0,0,0,0.25)] hover:bg-[#fee22e] focus:outline-none focus:ring focus:ring-[#fee22e] focus:ring-opacity-75 whitespace-nowrap  text-black"
                                            onClick={() => {
                                                props.deleverageFunction(lpsToRedeem, amountAMin, amountBMin)
                                            }}
                                        >
                                            {props.title}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );
};

export default DeleverageModal;
