import { FC, HTMLInputTypeAttribute } from "react";

interface MiniEditablePriceProps {
    className?: string;
    title: string;
    value: number | undefined;
    icon: string;
    iconAlt: string;
    right?: boolean;
    type: HTMLInputTypeAttribute;
    step?: number;
    label?: string;
    onChange: (val: React.ChangeEvent<HTMLInputElement>) => void;
    min?: number;
    max?: number;
    elementId: string;
}

const MiniEditablePrice: FC<MiniEditablePriceProps> = (props) => {
    return (
        <div
            className={
                "flex-1 flex rounded-lg w-full items-center bg-white px-2 py-1 font-urbanist dark:from-[#31333c] dark:to-[#5a5d6b] dark:text-light" +
                (props.right
                    ? " drop-shadow-[-3px_0px_4px_rgba(0,0,0,0.15)] dark:bg-gradient-to-l"
                    : " drop-shadow-[3px_0px_4px_rgba(0,0,0,0.15)] dark:bg-gradient-to-r") +
                (props.className ? ` ${props.className}` : "")
            }
        >
            <input
                type={props.type}
                step={props.step}
                min={props.min ? props.min : 0}
                // max={props.max}
                className={
                    "flex uppercase flex-col w-full flex-1 dark:from-[#31333c] dark:bg-transparent dark:to-[#5a5d6b] dark:text-light px-2 py-1" +
                    (props.right ? " text-right order-2 ml-2" : " mr-2")
                }
                placeholder={`${props.title}`}
                id={props.elementId}
                onChange={(ev) => props.onChange(ev)}
                value={props.value}
            />
            {/* <div className="text-xs italic font-bold leading-3">{props.title}</div> */}
            {/* <div className="text-sm font-bold leading-3">{props.value}</div> */}
            {/* </input> */}
            <div className={"shrink-0 flex"}>
                <div className="self-center mr-2">{props.label}</div>
                <img src={props.icon} alt={props.iconAlt} className="h-5" />
            </div>
        </div>
    );
};

export default MiniEditablePrice;
