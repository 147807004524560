import React, { useContext, useEffect } from 'react';
import { Web3Context } from '../Components/Web3Context/Web3Context';
import RiskCard, { InvestmentType } from '../Cards/RiskCard';
import SocialBar from '../Components/Social/SocialBar';
import { getLendingPoolTokenBorrowAPY, getLendingPoolTokenSupplyAPY } from '../Common/LendingPoolFunctions';
import { useLocation } from 'react-router-dom';
import SwapWidget from '../Components/SwapWidget/SwapWidget';

function HomePage() {
  const { lendingGraph, farmApyStats, lpStats } = useContext(Web3Context);
  const search = useLocation().search;

  useEffect(() => {
    const vconsole = new URLSearchParams(search).get('vconsole');
    if (vconsole === 'yes') {
      const VConsole = require('vconsole');
      const vConsole = new VConsole({ theme: 'dark' });
    }
  }, []);

  const stakeApr =
    Math.max(
      lendingGraph?.data.lendingPools[0]
        ? getLendingPoolTokenSupplyAPY(lendingGraph?.data.lendingPools[0].borrowable0)
        : 0,
      lendingGraph?.data.lendingPools[0]
        ? getLendingPoolTokenSupplyAPY(lendingGraph?.data.lendingPools[0].borrowable1)
        : 0,
      // lendingGraph?.data.lendingPools[1] ? getLendingPoolTokenSupplyAPY(lendingGraph?.data.lendingPools[1].borrowable0) : 0,
      // lendingGraph?.data.lendingPools[1] ? getLendingPoolTokenSupplyAPY(lendingGraph?.data.lendingPools[1].borrowable1) : 0
    ) * 100 || 0;
  const stakeTvl =
    Number(lendingGraph?.data.lendingPools[0].borrowable0.totalSupplyUSD) +
      Number(lendingGraph?.data.lendingPools[0].borrowable1.totalSupplyUSD) || 0;

  const farmApr = farmApyStats
    ? Math.max(
        Number(farmApyStats.data['bomb-bomb-btcb'].vaultApy),
        // Number(farmApyStats.data['bomb-b2share-wbnb'].vaultApr),
        // Number(farmApyStats.data['bomb-bomb-btcb'].totalApy),
        // Number(farmApyStats.data['bomb-b2share-wbnb'].totalApy),
      ) * 100
    : 0;
  const farmTvl =
    Number(lendingGraph?.data.lendingPools.reduce<number>((p, c) => p + Number(c.collateral.totalBalanceUSD), 0)) || 0;

  // Rought borrow APR calculation by averaging 50-50 borrow rate and 10x leverage
  let borrowApr = 0;
  if (lpStats && farmApyStats) {
    const borrowApr00 = lendingGraph?.data.lendingPools[0]
      ? getLendingPoolTokenBorrowAPY(lendingGraph?.data.lendingPools[0].borrowable0) * 100
      : 0;
    const borrowApr01 = lendingGraph?.data.lendingPools[0]
      ? getLendingPoolTokenBorrowAPY(lendingGraph?.data.lendingPools[0].borrowable1) * 100
      : 0;
    // const borrowApr10 = lendingGraph?.data.lendingPools[1]
    //   ? getLendingPoolTokenBorrowAPY(lendingGraph?.data.lendingPools[1].borrowable0) * 100
    //   : 0;
    // const borrowApr11 = lendingGraph?.data.lendingPools[1]
    //   ? getLendingPoolTokenBorrowAPY(lendingGraph?.data.lendingPools[1].borrowable1) * 100
    //   : 0;

    const borrowApr0 = borrowApr00 + borrowApr01 / 2;
    // const borrowApr1 = borrowApr10 + borrowApr11 / 2;

    const borrowLeverage = 10;
    borrowApr = borrowLeverage * farmApyStats.data['bomb-bomb-btcb'].vaultApy * 100 - borrowApr0;
    // farmApyStats.data['bomb-b2share-wbnb'].vaultApr * 100 - borrowApr1,

    borrowApr = borrowLeverage * Math.max(farmApyStats.data['bomb-bomb-btcb'].vaultApy * 100 - borrowApr0);
  }

  const borrowTvl =
    Number(
      lendingGraph?.data.lendingPools.reduce<number>(
        (p, c) => p + Number(c.borrowable0.totalSupplyUSD) + Number(c.borrowable1.totalBorrowsUSD),
        0,
      ),
    ) || 0;

  return (
    <>
      <main className="flex flex-col w-100 flex-1 relative focus:outline-none dark:text-light">
        <h1 className="text-black-500 text-left text-2xl sm:text-3xl md:text-4xl font-semibold mb-4">
          Start earning reliable profits today with the{' '}
          <span className="italic font-extrabold whitespace-nowrap">
            <span className="text-primary-500">//</span> BOMB MAX App <span className="text-primary-500">//</span>
          </span>
        </h1>
        <div className="mb-6 font-bold ">To begin investing with BOMB MAX, select your preferred risk profile:</div>
        <div className="max-w-lg lg:max-w-none rounded-lg flex-1 mt-2 mx-auto md:mx-0 contents md:block">
          <div className="max-w-lg grid gap-8 grid-cols-1 md:grid-cols-1 lg:grid-cols-3 lg:max-w-none mx-auto mx-0">
            <RiskCard
              title="Low Risk"
              action="Stake"
              explanation="Not sure where to begin? Start your DeFi journey with the BOMB Single Asset Staking platform to earn the most stable rewards."
              buttonText="Start Staking"
              buttonLink="/stake"
              investmentType={InvestmentType.STAKE}
              tvl={stakeTvl}
              apr={stakeApr}
            />
            <RiskCard
              title="Medium Risk"
              action="Farm"
              explanation="Our farming pools offer greater rewards for anyone experienced with DeFi, these greater rewards come with additional risk."
              buttonText="Start Farming"
              buttonLink="/farm"
              investmentType={InvestmentType.FARM}
              tvl={farmTvl}
              apr={farmApr}
            />
            <RiskCard
              title="Higher Risk"
              action="Borrow"
              explanation="If you are looking for the ultimate returns on your investments our borrowing protocol is for you. This carries the highest risk."
              buttonText="Start Borrowing"
              buttonLink="/borrow"
              investmentType={InvestmentType.BORROW}
              tvl={borrowTvl}
              apr={borrowApr}
            />
          </div>
        </div>
        <SwapWidget />
        <SocialBar className="mt-12" />
      </main>
    </>
  );
}

export default HomePage;
