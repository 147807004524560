import React, { FC, useState } from "react";
import "./InputSlider.css";


interface InputSliderProps {
    className?: string;

    percent: number;
    setPercent: (percent: number) => void;
    range?: number[];
    step?: number;
}


const InputSlider: FC<InputSliderProps> = (props) => {
    const range = (props.range ?? [0, 25, 50, 75, 100]).sort((a, b) => a - b);
    const step = props.step ?? 25;

    function handleInputUpdate(event: React.FormEvent<HTMLInputElement>) {
        var value = Number((event.target as HTMLInputElement).value);
        props.setPercent(value);
    }

    return (
        <div className={"py-6 " + props.className}>
            <input type="range" min={range[0]} max={range[range.length - 1]} step={step} value={props.percent} id="fader" list="rangelist" onInput={handleInputUpdate} className="w-full" />

            <datalist id="rangelist">
                {range.map((x) => <option>{x.toString()}</option>)}
            </datalist>
        </div>
    );
};

export default InputSlider;