import { FC, useContext, useEffect, useState } from 'react';
import ConnectButton from '../Components/ConnectButton/ConnectButton';
import { round, roundAndFormatNumber } from '../Common/HelperFunctions';
import { Web3Context } from '../Components/Web3Context/Web3Context';
import logo from '../Images/bombmax-512.png';
import imgStake from '../Images/stake.png';
import imgFarm from '../Images/farm.png';
import imgBorrow from '../Images/borrow.png';
import Gauge from '../Components/CardControls/Gauge';
import { getLendingPoolTokenSupplyAPY } from '../Common/LendingPoolFunctions';

interface SummaryPanelProps {
  className?: string;
}
enum SelectionDuration {
  DAY,
  WEEK,
  MONTH,
  YEAR,
}

const SummaryPanel: FC<SummaryPanelProps> = (props) => {
  const ANNUAL_SECONDS = 31556952;
  const {
    connectWeb3,
    disconnectWeb3,
    web3Connected,
    walletAddress,
    lendingGraph,
    // userPositions,
    fetchAssetBalance,
    fetchAssetDebt,
    fetchAssetExchangeRate,
    farmApyStats,
  } = useContext(Web3Context);
  const [selectedDuration, setSelectedDuration] = useState(SelectionDuration.DAY);

  const [stakeBalance, setStakeBalance] = useState<number>(0);
  const [farmBalance, setFarmBalance] = useState<number>(0);
  const [borrowBalance, setBorrowBalance] = useState<number>(0);
  const [annualStakeReturn, setAnnualStakeReturn] = useState<number>(0);
  const [annualFarmReturn, setAnnualFarmReturn] = useState<number>(0);
  // const [isLoading, setIsLoading] = useState<boolean>(true);

  async function fetchTotalStakeBalance() {
    var ret = 0;

    var contractAddresses = [];
    if (lendingGraph?.data.lendingPools[0].borrowable0.underlying.id)
      contractAddresses.push(lendingGraph?.data.lendingPools[0].borrowable0);
    if (lendingGraph?.data.lendingPools[0].borrowable1.underlying.id)
      contractAddresses.push(lendingGraph?.data.lendingPools[0].borrowable1);
    // if (lendingGraph?.data.lendingPools[1].borrowable0.underlying.id)
    //   contractAddresses.push(lendingGraph?.data.lendingPools[1].borrowable0);
    // if (lendingGraph?.data.lendingPools[1].borrowable1.underlying.id)
    //   contractAddresses.push(lendingGraph?.data.lendingPools[1].borrowable1);

    for (var i = 0; i < contractAddresses.length; i++) {
      var borrowable = contractAddresses[i];
      var balance = await fetchAssetBalance(borrowable.id);
      var borrowableExchangeRateUsd = Number(borrowable.totalBalanceUSD) / Number(borrowable.totalBalance);
      var amount = balance * Number(borrowable.exchangeRate) * borrowableExchangeRateUsd;
      ret += amount;
    }

    setStakeBalance(ret);
  }

  async function fetchTotalFarmBalance() {
    var ret = 0;

    var contractAddresses = [];
    if (lendingGraph?.data.lendingPools[0].collateral)
      contractAddresses.push(lendingGraph?.data.lendingPools[0].collateral);
    // if (lendingGraph?.data.lendingPools[1].collateral)
    //   contractAddresses.push(lendingGraph?.data.lendingPools[1].collateral);
    for (var i = 0; i < contractAddresses.length; i++) {
      var collateral = contractAddresses[i];
      var balance = await fetchAssetBalance(collateral.id);
      // var exchangeRate = await fetchAssetExchangeRate(collateral.id);
      // var underlyingBalance = balance * exchangeRate;
      //   console.log({ balance });
      var assetExchangeRate = Number(collateral.totalBalanceUSD) / Number(collateral.totalBalance);
      var amount = balance * assetExchangeRate;
      //   console.log({ assetExchangeRate });
      ret += amount;
    }
    // console.log({ ret });
    //console.log('farmBalance', ret);

    setFarmBalance(ret);
  }

  async function fetchTotalBorrowBalance() {
    var ret = 0;

    var contractAddresses = [];
    if (lendingGraph?.data.lendingPools[0].collateral) {
      contractAddresses.push(lendingGraph?.data.lendingPools[0].collateral);

      for (var i = 0; i < contractAddresses.length; i++) {
        var collateral = contractAddresses[i];
        const debt0 = await fetchAssetDebt(lendingGraph?.data.lendingPools[0].borrowable0.id);
        const debt1 = await fetchAssetDebt(lendingGraph?.data.lendingPools[0].borrowable1.id);
        const multiplier = Number(await fetchAssetExchangeRate(collateral.id));
        const debt0Usd = debt0 * lendingGraph?.data.lendingPools[0].borrowable0.underlying.derivedUSD;
        const debt1Usd = debt1 * lendingGraph?.data.lendingPools[0].borrowable1.underlying.derivedUSD;
        const lpUsd = lendingGraph?.data.lendingPools[0].pair.derivedUSD / multiplier;
        // debugger;
        const debtInLps = (debt0Usd + debt1Usd) / lpUsd;

        var assetExchangeRate = Number(collateral.totalBalanceUSD) / Number(collateral.totalBalance);
        var amount = (debtInLps / multiplier) * assetExchangeRate;

        ret += amount;
      }
    }
    // contractAddresses = [];
    // if (lendingGraph?.data.lendingPools[1].collateral) {
    //   contractAddresses.push(lendingGraph?.data.lendingPools[1].collateral);

    //   for (var i = 0; i < contractAddresses.length; i++) {
    //     collateral = contractAddresses[i];
    //     const debt0 = await fetchAssetDebt(lendingGraph?.data.lendingPools[1].borrowable0.id);
    //     const debt1 = await fetchAssetDebt(lendingGraph?.data.lendingPools[1].borrowable1.id);
    //     const multiplier = Number(await fetchAssetExchangeRate(collateral.id));
    //     const debt0Usd = debt0 * lendingGraph?.data.lendingPools[1].borrowable0.underlying.derivedUSD;
    //     const debt1Usd = debt1 * lendingGraph?.data.lendingPools[1].borrowable1.underlying.derivedUSD;
    //     const lpUsd = lendingGraph?.data.lendingPools[1].pair.derivedUSD / multiplier;
    //     // debugger;
    //     const debtInLps = (debt0Usd + debt1Usd) / lpUsd;

    //     var assetExchangeRate = Number(collateral.totalBalanceUSD) / Number(collateral.totalBalance);
    //     var amount = (debtInLps / multiplier) * assetExchangeRate;

    //     ret += amount;
    //   }
    // }

    setBorrowBalance(ret);
  }

  async function fetchAnnualFarmReturn() {
    var ret = 0;

    //farming
    var farmingContractAddresses = [];
    if (lendingGraph?.data.lendingPools[0].collateral)
      farmingContractAddresses.push(lendingGraph?.data.lendingPools[0].collateral);

    // if (lendingGraph?.data.lendingPools[1].collateral)
    //   farmingContractAddresses.push(lendingGraph?.data.lendingPools[1].collateral);

    for (var i = 0; i < farmingContractAddresses.length; i++) {
      var collateral = farmingContractAddresses[i];
      var balance = await fetchAssetBalance(collateral.id);
      var assetExchangeRate = Number(collateral.totalBalanceUSD) / Number(collateral.totalBalance);
      var amount = balance * assetExchangeRate;
      var farmApr = farmApyStats
        ? Number(
            farmApyStats.data[
              'bomb-' +
                lendingGraph?.data.lendingPools[i].borrowable0.underlying.symbol.toLowerCase() +
                '-' +
                lendingGraph?.data.lendingPools[i].borrowable1.underlying.symbol.toLowerCase()
              // ].totalApy,
            ].vaultApr,
          ) * 100
        : 0;
      var farmApy = farmApyStats
        ? Number(
            farmApyStats.data[
              'bomb-' +
                lendingGraph?.data.lendingPools[i].borrowable0.underlying.symbol.toLowerCase() +
                '-' +
                lendingGraph?.data.lendingPools[i].borrowable1.underlying.symbol.toLowerCase()
            ].totalApy,
            // ].vaultApr,
          ) * 100
        : 0;

      var apr = Number(farmApr / 100);
      var retAmount = amount * apr;
      ret += retAmount;
    }

    // console.log({ ret });
    // console.log({ farmBalance });
    setAnnualFarmReturn(ret);

    // if (ret > 0) setIsLoading(false);
  }
  // console.log({ isLoading });
  async function fetchAnnualStakeReturn() {
    var ret = 0;

    //stakes
    var stakeContractAddresses = [];
    if (lendingGraph?.data.lendingPools[0].borrowable0.underlying.id)
      stakeContractAddresses.push(lendingGraph?.data.lendingPools[0].borrowable0);
    if (lendingGraph?.data.lendingPools[0].borrowable1.underlying.id)
      stakeContractAddresses.push(lendingGraph?.data.lendingPools[0].borrowable1);
    // if (lendingGraph?.data.lendingPools[1].borrowable0.underlying.id)
    //   stakeContractAddresses.push(lendingGraph?.data.lendingPools[1].borrowable0);
    // if (lendingGraph?.data.lendingPools[1].borrowable1.underlying.id)
    //   stakeContractAddresses.push(lendingGraph?.data.lendingPools[1].borrowable1);

    for (var i = 0; i < stakeContractAddresses.length; i++) {
      var borrowable = stakeContractAddresses[i];
      var balance = await fetchAssetBalance(borrowable.id);
      var borrowableExchangeRateUsd = Number(borrowable.totalBalanceUSD) / Number(borrowable.totalBalance);
      var amount = balance * Number(borrowable.exchangeRate) * borrowableExchangeRateUsd;
      var apr = getLendingPoolTokenSupplyAPY(borrowable);
      var retAmount = amount * apr;
      ret += retAmount;
    }

    setAnnualStakeReturn(ret);
  }

  useEffect(() => {
    if (web3Connected) {
      fetchTotalStakeBalance();
      fetchTotalFarmBalance();
      fetchTotalBorrowBalance();
      fetchAnnualFarmReturn();
      fetchAnnualStakeReturn();
    }
  }, [lendingGraph, web3Connected]);

  //balance calculations
  // const stakeBalance = fetchTotalStakeBalance();
  // userPositions?.data.user?.supplyPositions.reduce<number>((previous, current) => {
  //     return previous + Number(current.balance) * Number(current.borrowable.underlying.derivedUSD);
  // }, 0) || 0;

  // const farmBalance = 0;
  // userPositions?.data.user?.collateralPositions.reduce<number>((previous, current) => {
  //     return previous + Number(current.collateral.totalBalanceUSD);
  // }, 0) || 0;
  // console.log(userPositions);
  // const borrowBalance = 0;
  // userPositions?.data.user?.borrowPositions.reduce<number>((previous, current) => {
  //     return previous + Number(current.borrowBalance) * Number(current.borrowable.underlying.derivedUSD);
  // }, 0) || 0;
  const finalStakeBalance = stakeBalance;
  const finalFarmBalance = farmBalance - borrowBalance;
  const finalBorrowBalance = borrowBalance;
  const totalBalance = finalStakeBalance + finalFarmBalance + finalBorrowBalance;

  // if (totalBalance === 0) {
  //   setIsLoading(false);
  // }
  // console.log({ totalBalance });

  // console.log('positions', userPositions);
  // if (
  //   farmBalance !== undefined &&
  //   farmBalance === 0 &&
  //   stakeBalance === 0 &&
  //   borrowBalance === 0 &&
  //   !userPositions?.data.user?.collateralPositions[0]
  // ) {
  //   setIsLoading(false);
  // } else {
  //   annualFarmReturn > 0 ? setIsLoading(false) : setIsLoading(true);
  // }
  //forecast calculations
  const stakeForecast = 0;
  const farmForecast = 0;
  const borrowForecast = 0;
  const totalForecast = stakeForecast + farmForecast + borrowForecast;

  function getDays(selectedDuration: SelectionDuration): number {
    var ret = 7;
    if (selectedDuration == SelectionDuration.DAY) ret = 1;
    else if (selectedDuration == SelectionDuration.WEEK) ret = 7;
    else if (selectedDuration == SelectionDuration.MONTH) ret = 365.25 / 12;
    else if (selectedDuration == SelectionDuration.YEAR) ret = 365.25;
    return ret;
  }
  // console.log('Loading: ', isLoading);
  return (
    <div className={'' + (props.className ? ` ${props.className}` : '')}>
      <div className="mb-8">
        <ConnectButton
          className=""
          connectWeb3={connectWeb3}
          disconnectWeb3={disconnectWeb3}
          web3Connected={web3Connected}
          walletAddress={walletAddress}
        />
      </div>
      <div className="flex flex-col w-full">
        <div className="p-4 bg-[#f2cb44] dark:bg-[#4b4689]  shadow-lg border-[#f2d03a]  rounded-xl dark:text-light">
          <div className="text-center text-lg font-bold dark:text-light">My Investments</div>
          <div className="text-center text-3xl font-bold text-white text-shadow-xs">
            ${roundAndFormatNumber(totalBalance)}
          </div>
          <div className="divide-y-2 divide-[#dcbf32] dark:divide-[#2d2975] mb-4">
            <div className="flex items-center py-2">
              {/* <a href="/stake"> */}
              <img className="w-6 mr-2" src={imgStake} alt="stake" />
              <div className="text-xs font-bold">STAKE</div>
              <div className="text-lg font-bold text-white ml-auto text-shadow-xs">
                ${roundAndFormatNumber(finalStakeBalance)}
              </div>
              {/* </a> */}
            </div>
            <div className="flex items-center py-2">
              {/* <a href="/farm"> */}
              <img className="w-6 mr-2" src={imgFarm} alt="farm" />
              <div className="text-xs font-bold">FARM</div>
              <div className="text-lg font-bold text-white ml-auto text-shadow-xs">
                ${roundAndFormatNumber(finalFarmBalance)}
              </div>
              {/* </a> */}
            </div>
            <div className="flex items-center py-2">
              {/* <a href="/borrow"> */}
              <img className="w-6 mr-2" src={imgBorrow} alt="farm" />
              <div className="text-xs font-bold">BORROW</div>
              <div className="text-lg font-bold text-white ml-auto text-shadow-xs">
                ${roundAndFormatNumber(finalBorrowBalance)}
              </div>
              {/* </a> */}
            </div>
          </div>
          <div className="flex justify-evenly">
            <div className="">
              <Gauge value={round((finalStakeBalance / totalBalance) * 100, 0) || 0} />
              <div className="text-xs text-center font-bold">STAKE</div>
            </div>
            <div className="">
              <Gauge value={round((finalFarmBalance / totalBalance) * 100, 0) || 0} />
              <div className="text-xs text-center font-bold">FARM</div>
            </div>
            <div className="">
              <Gauge value={round((finalBorrowBalance / totalBalance) * 100, 0) || 0} />
              <div className="text-xs text-center font-bold">BORROW</div>
            </div>
          </div>
        </div>
        <div className="relative flex py-6 items-center">
          <div className="flex-grow border-t border-[#dcbf32] dark:border-[#2d2975]"></div>
          <span className="flex-shrink mx-1">
            <img className="h-12" src={logo} alt="bomb" />
          </span>
          <div className="flex-grow border-t border-[#dcbf32] dark:border-[#2d2975]"></div>
        </div>

        <div className="p-4 bg-[#f2cb44] dark:bg-[#4b4689] shadow-lg border-[#f2d03a] rounded-xl dark:text-light">
          <div className="grid grid-cols-4 py-2 px-2 rounded-full items-center relative shadow-lg mb-4 bg-gradient-to-b from-[#fdd744] to-[#f1c600] dark:from-[#46428f] dark:to-[#2d2975]">
            <div
              onClick={() => setSelectedDuration(SelectionDuration.DAY)}
              className={
                'text-xs font-bold flex items-center justify-center cursor-pointer' +
                (selectedDuration === SelectionDuration.DAY
                  ? ' h-12 w-12 border-2 border-white rounded-full absolute left-[calc(12.5%-(48px/2)+6px)] drop-shadow-[-3px_3px_5px_rgba(255,255,255,0.5)] text-white text-[0.6rem] bg-gradient-to-b from-[#fdd744] to-[#f1c600] dark:from-[#46428f] dark:to-[#2d2975]'
                  : '')
              }
            >
              DAY
            </div>
            <div
              className={
                'text-xs font-bold flex items-center justify-center' +
                (selectedDuration !== SelectionDuration.DAY ? ' hidden' : '')
              }
            ></div>
            <div
              onClick={() => setSelectedDuration(SelectionDuration.WEEK)}
              className={
                'text-xs font-bold flex items-center justify-center cursor-pointer' +
                (selectedDuration === SelectionDuration.WEEK
                  ? ' h-12 w-12 border-2 border-white rounded-full absolute left-[calc(37.5%-(48px/2)+4px)] drop-shadow-[-3px_3px_5px_rgba(255,255,255,0.5)] text-white text-[0.6rem] bg-gradient-to-b from-[#fdd744] to-[#f1c600] dark:from-[#46428f] dark:to-[#2d2975]'
                  : '')
              }
            >
              WEEK
            </div>
            <div
              className={
                'text-xs font-bold flex items-center justify-center' +
                (selectedDuration !== SelectionDuration.WEEK ? ' hidden' : '')
              }
            ></div>
            <div
              onClick={() => setSelectedDuration(SelectionDuration.MONTH)}
              className={
                'text-xs font-bold flex items-center justify-center cursor-pointer' +
                (selectedDuration === SelectionDuration.MONTH
                  ? ' h-12 w-12 border-2 border-white rounded-full absolute left-[calc(62.5%-(48px/2)-2px)] drop-shadow-[-3px_3px_5px_rgba(255,255,255,0.5)] text-white text-[0.6rem] bg-gradient-to-b from-[#fdd744] to-[#f1c600] dark:from-[#46428f] dark:to-[#2d2975]'
                  : '')
              }
            >
              MONTH
            </div>
            <div
              className={
                'text-xs font-bold flex items-center justify-center' +
                (selectedDuration !== SelectionDuration.MONTH ? ' hidden' : '')
              }
            ></div>
            <div
              onClick={() => setSelectedDuration(SelectionDuration.YEAR)}
              className={
                'text-xs font-bold flex items-center justify-center cursor-pointer' +
                (selectedDuration === SelectionDuration.YEAR
                  ? ' h-12 w-12 border-2 border-white rounded-full absolute left-[calc(87.5%-(48px/2)-6px)] drop-shadow-[-3px_3px_5px_rgba(255,255,255,0.5)] text-white text-[0.6rem] bg-gradient-to-b from-[#fdd744] to-[#f1c600] dark:from-[#46428f] dark:to-[#2d2975]'
                  : '')
              }
            >
              YEAR
            </div>
            <div
              className={
                'text-xs font-bold flex items-center justify-center' +
                (selectedDuration !== SelectionDuration.YEAR ? ' hidden' : '')
              }
            ></div>
          </div>

          {(farmBalance !== 0 && annualFarmReturn > 0) || farmBalance === 0 ? (
            <>
              <div className="text-center text-lg font-bold">Earning Forecast</div>
              <div className="text-center text-3xl font-bold text-white text-shadow-xs">
                ${roundAndFormatNumber((annualFarmReturn + annualStakeReturn) * (getDays(selectedDuration) / 365.25))}
              </div>
              <div className="text-center text-xs font-bold text-white text-shadow-xs">
                PER {Object.values(SelectionDuration)[selectedDuration].toString().toUpperCase()}
              </div>

              <div className="divide-y-2 divide-[#dcbf32] dark:divide-[#2d2975] mb-4">
                <div className="flex items-center py-2">
                  <img className="w-6 mr-2" src={imgStake} alt="stake" />
                  <div className="text-xs font-bold">STAKE</div>
                  <div className="text-lg font-bold text-white ml-auto text-shadow-xs">
                    ${roundAndFormatNumber(annualStakeReturn * (getDays(selectedDuration) / 365.25))}
                  </div>
                </div>
                <div className="flex items-center py-2">
                  <img className="w-6 mr-2" src={imgFarm} alt="farm" />
                  <div className="text-xs font-bold">FARM & BORROW</div>
                  <div className="text-lg font-bold text-white ml-auto text-shadow-xs">
                    ${roundAndFormatNumber(annualFarmReturn * (getDays(selectedDuration) / 365.25))}
                  </div>
                </div>

                <div className="flex items-center py-2">
                  {/* <img className="w-6 mr-2" src={imgBorrow} alt="farm" /> */}
                  <div className="text-xs">
                    Actual numbers higher. <br />
                    This does not include compounds
                  </div>
                  {/* <div className="text-lg font-bold text-white ml-auto text-shadow-xs">${roundAndFormatNumber(0)}</div> */}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="text-center text-lg font-bold">Loading...</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SummaryPanel;
