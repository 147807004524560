import React, { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import MiniPrice from '../Components/CardControls/MiniPrice';
import iconStakedUsd from '../Images/staked-dollars-icon.png';
import MiniEditablePrice from '../Components/CardControls/MiniEditablePrice';
import iconLock from '../Images/lock.png';
import Transak from '../Components/Transak/Transak';
import { Web3Context } from '../Components/Web3Context/Web3Context';
import { roundAndFormatNumber } from '../Common/HelperFunctions';

interface ZapModalProps {
  isOpen: boolean;
  title: string;
  zapFunction: Function;
  onRequestClose: Function;
  amountElementId: string;
  symbol: string;
  symbolAddress: string;
  symbolIsLp: boolean;
}

const ZapModal: FC<ZapModalProps> = (props) => {
  const {
    web3Connected,
    walletAddress,
    bnbBalance,
    fetchAssetBalance,
    fetchApprovalAmountForZapper,
    approveAmountForZapper,
    estimateSwapAmountForZapper,
    zapIn,
    zapInToken,
  } = useContext(Web3Context);
  const [walletBalance, setWalletBalance] = useState<number>(0);
  const [fromToken, setFromToken] = useState<string>('BNB');
  const [fromTokenAddress, setFromTokenAddress] = useState<string>('000000000000');
  const [fromTokenDecimals, setFromTokenDecimals] = useState<number>(18);
  const [amount, setAmount] = useState<number>(0);
  const [approvedAmount, setApprovedAmount] = useState<number>(0);
  const [estimatedSwapAmount, setEstimatedSwapAmount] = useState<[number, number, number] | null>(null);
  const [maxSlippage, setMaxSlippage] = useState<number>(2);

  useEffect(() => {
    if (web3Connected) {
      fetchApproval();
      fetchWalletBalance();
      fetchEstimatedSwapAmount();
    }
  }, [fromTokenAddress, web3Connected, walletAddress, props.symbolAddress]);

  useEffect(() => {
    if (web3Connected) {
      fetchEstimatedSwapAmount();
    }
  }, [fromTokenAddress, web3Connected, walletAddress, props.symbolAddress, amount]);

  async function fetchApproval() {
    if (fromTokenAddress === '000000000000') {
      setApprovedAmount(10000000);
      return;
    }
    var allow = await fetchApprovalAmountForZapper(fromTokenAddress, fromTokenDecimals);
    setApprovedAmount(allow);
  }

  async function fetchWalletBalance() {
    if (fromTokenAddress === '000000000000') {
      setWalletBalance(bnbBalance);
      return;
    }
    const balance = await fetchAssetBalance(fromTokenAddress);
    setWalletBalance(balance);
  }

  async function fetchEstimatedSwapAmount() {
    if (amount <= 0) {
      return;
    }

    const uestimatedSwapAmount = await estimateSwapAmountForZapper(
      fromTokenAddress,
      props.symbolAddress,
      props.symbolIsLp,
      amount,
    );
    setEstimatedSwapAmount(uestimatedSwapAmount);
  }

  async function approve(poolToken: string, decimals: number) {
    await approveAmountForZapper(poolToken, decimals);
    await fetchApproval();
  }

  async function doZapIn(fromTokenAddress: string, amount: number) {
    if (!estimatedSwapAmount) {
      return;
    }

    let uestimatedSwapAmount;
    let outputAmountMin = estimatedSwapAmount[2] / Math.sqrt(1 + maxSlippage / 100);
    if (fromTokenAddress === '000000000000') {
      zapIn(props.symbolAddress, amount, outputAmountMin);
      await fetchWalletBalance();
      return;
    }

    zapInToken(fromTokenAddress, props.symbolAddress, amount, outputAmountMin);
    await fetchWalletBalance();
  }

  async function updateAmount(elem: HTMLInputElement) {
    console.log(`Element: ${elem.id}   Value: ${elem.value}`);
    setAmount(elem.value === '' ? 0 : elem.valueAsNumber);
  }

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      props.onRequestClose(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  const fromTokenSelectRef = useRef<any>();

  let fromTokens: { [key: string]: { symbol: string; decimals: number } };

  if (props.symbol === 'BOMB') {
    fromTokens = {
      '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c': { symbol: 'BTCB', decimals: 18 },
      // '0x0DF73831C00B157bB0FeD3c06eB475F201B64a78': { symbol: 'B2SHARE', decimals: 18 },
      // '0x2170Ed0880ac9A755fd29B2688956BD959F933F8': { symbol: 'ETH', decimals: 18 },
      // '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56': { symbol: 'BUSD', decimals: 18 },
      // '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d': { symbol: 'USDC', decimals: 18 },
      '0x55d398326f99059fF775485246999027B3197955': { symbol: 'USDT', decimals: 18 },
    };
  } else {
    fromTokens = {
      '000000000000': { symbol: 'BNB', decimals: 18 },
      '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c': { symbol: 'BTCB', decimals: 18 },
      '0x522348779DCb2911539e76A1042aA922F9C47Ee3': { symbol: 'BOMB', decimals: 18 },
      '0x0DF73831C00B157bB0FeD3c06eB475F201B64a78': { symbol: 'B2SHARE', decimals: 18 },
      // '0x2170Ed0880ac9A755fd29B2688956BD959F933F8': { symbol: 'ETH', decimals: 18 },
      // '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56': { symbol: 'BUSD', decimals: 18 },
      // '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d': { symbol: 'USDC', decimals: 18 },
      // '0x55d398326f99059fF775485246999027B3197955': { symbol: 'USDT', decimals: 18 },
    };
  }

  return (
    <>
      {props.isOpen ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none dark:bg-dark2-500">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t dark:text-light">
                  <h3 className="text-3xl font-semibold">{props.title}</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => props.onRequestClose(false)}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none dark:text-light">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <div className="my-4 mt-0 mb-8 text-slate-500 text-center leading-relaxed dark:text-light">
                    <div>Don't have BNB yet?</div>
                    <div>
                      <Transak className="relative inline-flex items-center pl-6 pr-4 py-2 border border-gray-500 shadow-sm text-sm font-medium rounded-full text-white bg-darkblue hover:bg-darkblue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-darkblue">
                        <span className="text-black dark:text-white uppercase pr-2">Buy BNB Now</span>
                      </Transak>
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <div className="mb-3 xl:w-96">
                      <label
                        htmlFor="exampleText0"
                        className="form-label inline-block mb-2 text-gray-700 dark:text-white"
                      >
                        Select token to convert into {props.symbol}
                      </label>
                      <select
                        ref={fromTokenSelectRef}
                        onChange={(e) => {
                          setAmount(0);
                          setFromTokenAddress(e.target.value);
                          setFromToken(
                            fromTokenSelectRef.current.options[fromTokenSelectRef.current.selectedIndex].text,
                          );
                          setFromTokenDecimals(fromTokens[e.target.value].decimals);
                        }}
                        defaultValue={'000000000000'}
                        className="form-select appearance-none block w-full px-3 py-1.5 text-base font-normal text-gray-700
                                                  bg-white bg-clip-padding bg-no-repeat
                                                  dark:bg-dark2-500
                                                  dark:text-white
                                                  border border-solid border-gray-300
                                                  rounded
                                                  transition
                                                  ease-in-out
                                                  m-0
                                                  focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        aria-label="Default select example"
                      >
                        {Object.entries(fromTokens).map(([key]) => (
                          <option key={key} value={key}>
                            {fromTokens[key].symbol}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <MiniPrice
                    icon={iconStakedUsd}
                    iconAlt="pie chart"
                    title={'Wallet'}
                    value={String(walletBalance)}
                    maxOnClick={() => {
                      const elem = document.getElementById(props.amountElementId) as HTMLInputElement;
                      // If fromToken is BNB then subtract a few digits to make sure there is enough to cover the gas
                      elem.value = String(
                        fromTokenAddress == '000000000000' ? walletBalance - 1 / Math.pow(10, 2) : walletBalance,
                      );
                      updateAmount(elem);
                    }}
                  />
                  <p style={{ lineHeight: '5px' }}>&nbsp;</p>
                  <div className="flex justify-evenly w-full gap-4 flex-wrap">
                    <MiniEditablePrice
                      icon={iconLock}
                      type="number"
                      iconAlt="lock"
                      title={`Enter Amount`}
                      value={amount}
                      elementId={props.amountElementId}
                      onChange={(val) => updateAmount(val.currentTarget)}
                      label={fromToken}
                    />
                  </div>
                </div>
                {estimatedSwapAmount !== null && (
                  <div className="justify-end dark:text-white text-right p-6 border-t border-solid border-slate-200 rounded-b">
                    <div>You will receive:</div>
                    {estimatedSwapAmount[2] && (
                      <>
                        <div>
                          {roundAndFormatNumber(estimatedSwapAmount[2], 8)} {props.symbol}
                        </div>
                      </>
                    )}
                    <p style={{ lineHeight: '5px' }}>&nbsp;</p>
                    <div className="text-sm">Max slippage: {maxSlippage}%</div>
                  </div>
                )}
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  {approvedAmount == 0 ? (
                    <button
                      className="flex-1 rounded-full bg-gradient-to-b from-[#fee22e] to-[#fed647] text-black uppercase px-5 py-2 font-semibold drop-shadow-[0px_4px_5px_rgba(0,0,0,0.25)] hover:bg-[#fee22e] focus:outline-none focus:ring focus:ring-[#fee22e] focus:ring-opacity-75 whitespace-nowrap"
                      onClick={async () => {
                        await approve(fromTokenAddress, fromTokenDecimals);
                      }}
                    >
                      Approve {fromToken}
                    </button>
                  ) : (
                    <button
                      className="flex-1 rounded-full bg-gradient-to-b from-[#fee22e] to-[#fed647] text-black uppercase px-5 py-2 font-semibold drop-shadow-[0px_4px_5px_rgba(0,0,0,0.25)] hover:bg-[#fee22e] focus:outline-none focus:ring focus:ring-[#fee22e] focus:ring-opacity-75 whitespace-nowrap"
                      onClick={() => {
                        if (amount <= 0) {
                          alert('Amount should not be 0');
                          return;
                        }
                        doZapIn(fromTokenAddress, amount);
                        props.zapFunction(amount);
                      }}
                    >
                      {props.title}
                    </button>
                  )}
                </div>
                <label
                  htmlFor="exampleText0"
                  className="pl-5 form-label inline-block mb-2 text-gray-700 dark:text-white"
                >
                  After zapping, you will need to deposit the
                  <br /> {props.symbol} into the pool to start earning.
                </label>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default ZapModal;
