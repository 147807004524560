import React, { useContext, useState } from "react";
import { abbreviateAddress, round, roundAndFormatNumber } from "../Common/HelperFunctions";
import { Web3Context } from "../Components/Web3Context/Web3Context";

function DebugPage() {
    const {
        web3Connected,
        providerName,
        walletAddress,
        bnbBalance,
        bnbUsdtPrice,
        routerBombApprovalAmount,
        routerBtcbApprovalAmount,
        routerBbombApprovalAmount,
        approveBbombForRouter,
        approveBombForRouter,
        approveBtcbForRouter,
    } = useContext(Web3Context);

    return (
        <>
            <main className="flex flex-col w-100 text-left flex-1 relative focus:outline-none dark:text-light">
                <h1 className="text-black-500 text-left text-3xl font-semibold">Debug</h1>
                <div className="max-w-lg lg:max-w-none mt-6 bg-white dark:bg-dark2-500 rounded-lg p-6 flex-1">
                    <h2 className="text-black-500 text-2xl font-semibold">Browser</h2>
                    <table className="mt-2 w-full border">
                        <thead>
                            <tr className="border-b">
                                <th>Key</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>User Agent</td>
                                <td>{navigator.userAgent}</td>
                            </tr>
                            <tr>
                                <td>Language</td>
                                <td>{navigator.language}</td>
                            </tr>
                            <tr>
                                <td>Vendor</td>
                                <td>{navigator.vendor}</td>
                            </tr>
                            <tr>
                                <td>Timezone</td>
                                <td>{Intl.DateTimeFormat().resolvedOptions().timeZone}</td>
                            </tr>
                        </tbody>
                    </table>

                    <h2 className="mt-6 text-black-500 text-2xl font-semibold">App</h2>
                    <table className="mt-2 w-full border">
                        <thead>
                            <tr className="border-b">
                                <th>Key</th>
                                <th>Value</th>
                                <th>Action/Notes</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>window.ethereum</td>
                                <td>{((window as any).ethereum != null).toString()}</td>
                            </tr>
                            <tr>
                                <td>Selected Provider</td>
                                <td>{providerName}</td>
                            </tr>
                            <tr>
                                <td>Provider Connected</td>
                                <td>{web3Connected.toString()}</td>
                            </tr>
                            <tr>
                                <td>Wallet Address</td>
                                <td>
                                    <a href={`https://www.bscscan.com/address/${walletAddress}`} target="_blank">
                                        {walletAddress}
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td>BNB Balance</td>
                                <td>{bnbBalance}</td>
                            </tr>
                            <tr>
                                <td>BNB/USDT Price</td>
                                <td>{bnbUsdtPrice}</td>
                            </tr>
                            <tr>
                                <td>Router BTCB Approval</td>
                                <td>{routerBtcbApprovalAmount}</td>
                                <td><button className="flex-none h-8 inline-block bg-primary-500 px-4 border border-transparent rounded-md text-base font-medium text-dark1-500 hover:bg-dark2-500 hover:text-primary-500 hover:border hover:border-primary-500 disabled:bg-dark2-500 disabled:text-gray-500 disabled:border disabled:border-gray-500" onClick={() => { approveBtcbForRouter() }} disabled={web3Connected == false || routerBtcbApprovalAmount > 0}>APPROVE</button></td>
                            </tr>
                            <tr>
                                <td>Router BOMB Approval</td>
                                <td>{routerBombApprovalAmount}</td>
                                <td><button className="flex-none h-8 inline-block bg-primary-500 px-4 border border-transparent rounded-md text-base font-medium text-dark1-500 hover:bg-dark2-500 hover:text-primary-500 hover:border hover:border-primary-500 disabled:bg-dark2-500 disabled:text-gray-500 disabled:border disabled:border-gray-500" onClick={() => { approveBombForRouter() }} disabled={web3Connected == false || routerBombApprovalAmount > 0}>APPROVE</button></td>
                            </tr>
                            <tr>
                                <td>Router BBOMB Approval</td>
                                <td>{routerBbombApprovalAmount}</td>
                                
                            </tr>
                        </tbody>
                    </table>
                </div>
                {/* <div className="max-w-lg grid gap-8 grid-cols-2 md:grid-cols-3 lg:grid-cols-5 lg:max-w-none mt-6"> */}
                {/* </div> */}
            </main>
        </>
    );
}

export default DebugPage;
