import React, { useCallback, useContext } from "react";
import transakSDK from "@transak/transak-sdk";
import { Web3Context } from "../Web3Context/Web3Context";

interface TransakProps {
    className?: string;
}

export const Transak: React.FC<TransakProps> = (props) => {
    const { transak: ktransak, walletAddress } = useContext(Web3Context);

    const init = useCallback(() => {
        if (ktransak) {
            let transak = new transakSDK({
                apiKey: ktransak.ApiKey, // Your API Key (Required)
                environment: ktransak.Environment, // STAGING/PRODUCTION (Required)
                defaultCryptoCurrency: "BNB",
                defaultNetwork: "bsc",
                walletAddress: walletAddress, // Your customer wallet address
                themeColor: "ffd918", // App theme color in hex
                email: "", // Your customer email address (Optional)
                redirectURL: "",
                hostURL: window.location.origin, // Required field
                widgetHeight: "550px",
                widgetWidth: "450px",
            });

            transak.init();

            // To get all the events
            transak.on(transak.ALL_EVENTS, (data: any) => {
                // console.log(data);
            });

            // This will trigger when the user closed the widget
            transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, (orderData: any) => {
                transak.close();
            });

            // This will trigger when the user marks payment is made.
            transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData: any) => {
                // console.log(orderData);
                transak.close();
            });
        }
    }, [ktransak]);

    return (
        <div className={"transak cursor-pointer" + (props.className ? ` ${props.className}` : "")} onClick={init}>
            {props.children}
        </div>
    );
};

export default Transak;
