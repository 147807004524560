import { FC } from "react";
import iconTwitter from "../../Images/twitter.png";
import iconFacebook from "../../Images/facebook.png";
import iconTelegram from "../../Images/telegram.png";
import iconMedium from "../../Images/medium.png";
import iconDiscord from "../../Images/discord.png";
import iconMail from "../../Images/mail.png";

interface SocialBarProps {
    className?: string;
}

const SocialBar: FC<SocialBarProps> = (props) => {
    return (
        <div className={"mb-4 md:mb-8" + (props.className ? ` ${props.className}` : "")}>
            <div className="flex justify-center gap-x-10 gap-y-2 mb-4 dark:brightness-0 dark:invert">
                <a href="https://twitter.com/BombMoneyBSC" target="_blank" rel="noreferrer">
                    <img src={iconTwitter} alt="twitter" className="h-6" />
                </a>
                {/* <a href="" target="_blank" rel="noreferrer">
                    <img src={iconFacebook} alt="facebook" className="h-6" />
                </a> */}
                <a href="https://t.me/bombmoneybsc" target="_blank" rel="noreferrer">
                    <img src={iconTelegram} alt="telegram" className="h-6" />
                </a>
                <a href="https://bombbshare.medium.com/" target="_blank" rel="noreferrer">
                    <img src={iconMedium} alt="medium" className="h-6" />
                </a>
                <a href="https://discord.com/invite/94Aa4wSz3e" target="_blank" rel="noreferrer">
                    <img src={iconDiscord} alt="discord" className="h-6" />
                </a>
                {/* <a href="mailto:" target="_blank" rel="noreferrer">
                    <img src={iconMail} alt="mail" className="h-6" />
                </a> */}
            </div>
            <div className="text-[#2b3147] text-2xl font-bold text-center dark:text-light">
                Become Part of Our Fast Growing Community
            </div>
        </div>
    );
};

export default SocialBar;
