import React, { useContext } from "react";
import "./App.css";
import Web3Context, { Web3Context as context } from "./Components/Web3Context/Web3Context";
import { Navigate, Route, Routes } from "react-router-dom";

import Layout from "./Layout/Layout";
import HomePage from "./Pages/HomePage";
import AdminPage from "./Pages/AdminPage";
import DebugPage from "./Pages/DebugPage";
import LendPage from "./Pages/StakePage";
import FarmPage from "./Pages/FarmPage";
import BorrowPage from "./Pages/BorrowPage";
import PortfolioPage from "./Pages/PortfolioPage";

function App() {
    return (
        <Web3Context>
            <Layout>
                <Routes>
                    <Route path="/home" element={<HomePage />} />
                    <Route path="/stake" element={<LendPage />} />
                    <Route path="/farm" element={<FarmPage />} />
                    <Route path="/borrow" element={<BorrowPage />} />
                    <Route path="/portfolio" element={<PortfolioPage />} />

                    <Route path="/debug" element={<DebugPage />} />
                    <Route path="/admin" element={<AdminPage />} />

                    {/* default home route */}
                    <Route path="/" element={<Navigate to="/home" />} />

                    {/* route catch-all */}
                    <Route path="*" element={<Navigate to="/" />} />

                    {/* parameterised routes */}
                    {/* <Route path="/nfts" element={<NftsPage />}>
                            <Route path=":nftContractAddress/:nftId" element={<NftsPage />} ></Route>
                            </Route> 
                        */}

                    {/* conditional route */}
                    {/* {useContext(context).isAdmin == true ? */}
                    {/* <Route path="/admin" element={<AdminPage />} /> */}
                    {/* : null} */}
                </Routes>
            </Layout>
        </Web3Context>
    );
}

export default App;
