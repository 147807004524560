import React, { FC, useCallback, useEffect, useState } from "react";
import MiniPrice from "../Components/CardControls/MiniPrice";
import iconStakedUsd from "../Images/staked-dollars-icon.png";
import MiniEditablePrice from "../Components/CardControls/MiniEditablePrice";
import { round, roundAndFormatNumber, getLiquidationPrices, getLeverage } from "../Common/HelperFunctions";
import iconLock from "../Images/lock.png";
import iconStaked from "../Images/staked-icon.png";
import { LendingPool } from "../Common/Models/LendingGraph";
import { LpStat } from "../Common/Models/LPStat";

interface StakeModalProps {
    isOpen: boolean;
    title: string;
    depositFunction: Function;
    onRequestClose: Function;
    walletBalance?: number;
    stakedBalance?: number;
    stakedBalanceMultiplier?: number;
    stakedDebt?: number;
    debt0?: number;
    debt1?: number;
    amountElementId: string;
    lpStat?: LpStat;
    lendingPool?: LendingPool;
    symbol: string;
}

const StakeModal: FC<StakeModalProps> = (props) => {
    let rawBalance = props.stakedBalance ? props.stakedBalance : props.walletBalance ? props.walletBalance : 0;
    let balance = round(rawBalance, 6)
    let rawDebt = props.stakedDebt ? props.stakedDebt : 0;
    let debt = round(rawDebt, 6);

    const [amount, setAmount] = useState<number>(0);

    let liquidationPrices = [0, Infinity];
    let newLiquidationPrices = [0, Infinity];
    let currentLeverage = 1;
    let newLeverage = 1;
    if (props.stakedBalance && props.stakedBalanceMultiplier && props.debt0 && props.debt1 && debt > 0) {
        const changes = {
            changeCollateral: -amount,
            changeBorrowedA: 0,
            changeBorrowedB: 0
        };
        liquidationPrices =
            getLiquidationPrices(
                props.stakedBalance / props.stakedBalanceMultiplier,
                Number(props.lpStat?.token0DenomLPPrice),
                Number(props.lpStat?.token1DenomLPPrice),
                props.debt0,
                props.debt1,
                Number(props.lpStat?.twap),
                Number(props.lendingPool?.collateral.safetyMargin),
                Number(props.lendingPool?.collateral.liquidationIncentive),
            );
        newLiquidationPrices =
            getLiquidationPrices(
                props.stakedBalance / props.stakedBalanceMultiplier,
                Number(props.lpStat?.token0DenomLPPrice),
                Number(props.lpStat?.token1DenomLPPrice),
                props.debt0,
                props.debt1,
                Number(props.lpStat?.twap),
                Number(props.lendingPool?.collateral.safetyMargin),
                Number(props.lendingPool?.collateral.liquidationIncentive),
                changes
            );
        currentLeverage =
            getLeverage(
                props.stakedBalance / props.stakedBalanceMultiplier,
                Number(props.lpStat?.token0DenomLPPrice),
                Number(props.lpStat?.token1DenomLPPrice),
                props.debt0,
                props.debt1,
            );
        newLeverage =
            getLeverage(
                props.stakedBalance / props.stakedBalanceMultiplier,
                Number(props.lpStat?.token0DenomLPPrice),
                Number(props.lpStat?.token1DenomLPPrice),
                props.debt0,
                props.debt1,
                changes
            );
    }

    async function updateAmount(elem: HTMLInputElement) {
        console.log(`Element: ${elem.id}   Value: ${elem.value}`);
        setAmount(elem.value === "" ? 0 : elem.valueAsNumber);
    }

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            props.onRequestClose(false)
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, []);

    return (
        <>
            {props.isOpen ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            {/*content*/}
                            <div
                                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none dark:bg-dark2-500">
                                {/*header*/}
                                <div
                                    className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t dark:text-light">
                                    <h3 className="text-3xl font-semibold">
                                        {props.title}
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => props.onRequestClose(false)}
                                    >
                    <span
                        className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none dark:text-light">
                      ×
                    </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    {/*<p className="my-4 text-slate-500 text-lg leading-relaxed dark:text-light">
                                        TODO - Potential Text Here
                                    </p>*/}
                                    <MiniPrice
                                        icon={iconStakedUsd}
                                        iconAlt="pie chart"
                                        title={props.stakedBalance ? 'Staked' : 'Wallet'}
                                        value={String(balance)}
                                        maxOnClick={() => {
                                            const elem = document.getElementById(props.amountElementId) as HTMLInputElement;
                                            elem.value = String(rawBalance - rawDebt);
                                            updateAmount(elem);
                                        }}
                                    />
                                    {debt > 0 && (
                                        <>
                                            <p style={{ 'lineHeight': '10px' }}>&nbsp;</p>
                                            <div className="flex justify-evenly w-full gap-4 mb-4 flex-wrap">
                                                <MiniPrice
                                                    icon={iconStakedUsd}
                                                    iconAlt="pie chart"
                                                    title={'Debt'}
                                                    textColor={'lightcoral'}
                                                    value={'-' + String(debt)}
                                                />
                                                <MiniPrice
                                                    icon={iconStaked}
                                                    iconAlt="lock"
                                                    title={`LP Equity`}
                                                    tooltip={`LP Equity = Total LP - Debt LP`}
                                                    value={roundAndFormatNumber(balance - debt, 5) + " LP"}
                                                    right
                                                />
                                            </div>
                                            <p style={{ 'lineHeight': '5px' }}>&nbsp;</p>
                                        </>
                                    )}
                                    <p style={{ 'lineHeight': '5px' }}>&nbsp;</p>
                                    <div className="flex justify-evenly w-full gap-4 flex-wrap">
                                        <MiniEditablePrice
                                            icon={iconLock}
                                            type="number"
                                            iconAlt="lock"
                                            title={`Enter Amount`}
                                            value={amount}
                                            elementId={props.amountElementId}
                                            onChange={(val) => updateAmount(val.currentTarget)}
                                            label={props.symbol}
                                        />
                                    </div>
                                    {debt > 0 && (
                                        <>
                                            <div className="text-right">
                                                <div style={{ 'lineHeight': '1rem' }}>&nbsp;</div>
                                                <div>New leverage:</div>
                                                {roundAndFormatNumber(newLeverage, 2)}x
                                            </div>
                                            <div className="text-right">
                                                <div style={{ 'lineHeight': '1rem' }}>&nbsp;</div>
                                                <div>New liquidation prices:</div>
                                                {props.lpStat?.ratio ? (roundAndFormatNumber(liquidationPrices[0] * props.lpStat?.ratio, 4) + ' - ' + roundAndFormatNumber(liquidationPrices[1] * props.lpStat?.ratio, 4)) : ''}
                                                &nbsp;
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                     stroke="currentColor" className="w-6 h-6" style={{"display": "inline"}}>
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                          d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                                                </svg>
                                                &nbsp;
                                                {props.lpStat?.ratio ? (roundAndFormatNumber(newLiquidationPrices[0] * props.lpStat?.ratio, 4) + ' - ' + roundAndFormatNumber(newLiquidationPrices[1] * props.lpStat?.ratio, 4)) : ''}
                                            </div>
                                        </>
                                    )}
                                </div>
                                {/*footer*/}
                                <div
                                    className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="flex-1 rounded-full bg-gradient-to-b from-[#fee22e] to-[#fed647] text-dark uppercase px-5 py-2 font-semibold drop-shadow-[0px_4px_5px_rgba(0,0,0,0.25)] hover:bg-[#fee22e] focus:outline-none focus:ring focus:ring-[#fee22e] focus:ring-opacity-75 whitespace-nowrap"
                                        onClick={() => {
                                            if (amount <= 0) {
                                                alert('Amount should not be 0');
                                                return;
                                            }
                                            if (newLeverage >= 10) {
                                                alert('Leverage cannot exceed 10x');
                                                return;
                                            }
                                            props.depositFunction(amount)
                                        }}
                                    >
                                        {props.title}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );
};

export default StakeModal;
